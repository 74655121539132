@charset "UTF-8";
.ap-accordion-content {
  transition: all 0.5s linear;
  overflow: hidden;
}

.ap-accordion-content.hide {
  height: 0;
}

.ap-accordion-content.open {
  height: auto;
}

.ap-accordion {
  border-radius: var(--border-radius-2);
  overflow: visible;
  position: relative;
  background-color: transparent;
  cursor: pointer;
  list-style: none;
  transition: background-color 0.3s;
}
.ap-accordion:not(:last-child) {
  margin-bottom: var(--spacing-2);
}
.ap-accordion:hover {
  background-color: var(--color-background-hover);
}
.ap-accordion .ap-accordion-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-4);
  cursor: pointer;
  overflow: hidden;
  border-radius: var(--border-radius-2);
  position: relative;
}
.ap-accordion .ap-accordion-toggle.ap-accordion-cursor-default .ap-accordion-toggle-icon-container {
  cursor: pointer;
  overflow: hidden;
}
.ap-accordion .ap-accordion-toggle .ap-accordion-title {
  height: 1.5rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  user-select: none;
  color: var(--color-text-heading);
  margin-right: var(--spacing-4);
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
}
.ap-accordion .ap-accordion-toggle .Appkit4-icon {
  transform: rotate(0);
  transition: transform 0.3s;
  color: var(--color-text-body);
}
.ap-accordion .ap-accordion-toggle .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-accordion .ap-accordion-toggle .Appkit4-icon.rotate {
  transform: rotate(-180deg);
}
.ap-accordion .ap-accordion-content {
  padding: 0 var(--spacing-8) 0 var(--spacing-4);
  color: var(--color-text-body);
  line-height: 1.5rem;
  transition: height 0.3s;
}
.ap-accordion .ap-accordion-content .ap-accordion-wrapper {
  padding: 0 0 var(--spacing-4) 0;
}
.ap-accordion .ap-accordion-content.hidden {
  height: 0;
  padding-bottom: 0;
}
.ap-accordion .ap-accordion-content p {
  margin: 0;
}
.ap-accordion .ap-accordion-content .ap-accordion-text {
  display: inline-block;
  line-height: 1.5rem;
}
.ap-accordion .ap-accordion-cursor-default {
  cursor: default;
}

.ap-accordion-demo-wrapper {
  width: 35.25rem;
}

.ap-accordion-group-container {
  padding: 0;
  margin: 0;
}

.react-slidedown {
  height: 0;
  transition-property: none;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  display: none;
}

.react-slidedown.closed {
  display: none;
}

.ap-avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-primary);
  color: var(--color-text-secondary);
  border-color: var(--color-text-primary);
  border-style: solid;
  font-weight: var(--font-weight-2);
  padding: var(--spacing-3);
  position: relative;
  user-select: none;
  cursor: pointer;
}
.ap-avatar.mask {
  mask-image: url("../images/avatar/avatar-mask.svg");
  -webkit-mask-image: url("../images/avatar/avatar-mask.svg");
  mask-position: right;
  -webkit-mask-position: right;
  mask-size: contain;
  -webkit-mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
}
.ap-avatar:not(.mask) {
  color: var(--color-text-tertiary);
}
.ap-avatar.disabled {
  cursor: default;
}
.ap-avatar:not(.disabled):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-avatar:not(.disabled):focus.keyboard-focus {
  outline: auto 2px Highlight; /* Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-avatar-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.ap-avatar-text.compact {
  font-size: 14px;
  line-height: 16px;
  font-weight: var(--font-weight-1);
}
.ap-avatar .back-mask {
  position: absolute;
  top: -0.25rem;
  left: -0.25rem;
  background-color: var(--color-background-primary);
  border-radius: 50%;
  opacity: var(--opacity-3);
}

.ap-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  padding: var(--spacing-2) var(--spacing-4);
}
.ap-badge.gesture {
  cursor: pointer;
}
.ap-badge span {
  font-size: 0.875rem;
  font-weight: var(--font-weight-2);
  width: 100%;
  display: flex;
  justify-content: center;
  line-height: 1rem;
  letter-spacing: -0.025rem;
  text-align: center;
}
.ap-badge.ap-badge-round {
  border-radius: 50%;
  min-width: 1.5rem;
  padding: var(--spacing-2);
}
.ap-badge.ap-badge-round.ap-badge-lg {
  min-width: 2rem;
  height: 2rem;
  font-size: 1rem;
}
.ap-badge.ap-badge-lg {
  height: 2rem;
  line-height: 2rem;
}
.ap-badge.ap-badge-lg span {
  font-size: 1rem;
  line-height: 1.5rem;
}
.ap-badge.ap-badge-primary {
  background-color: var(--color-background-primary);
  color: var(--color-background-secondary);
}
.ap-badge.ap-badge-primary-outlined {
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-3));
  color: var(--color-background-primary);
  box-shadow: 0 0 0 0.0625rem var(--color-background-primary);
}
.ap-badge.ap-badge-danger {
  background-color: #C52A1A;
  color: var(--color-background-secondary);
}
.ap-badge.ap-badge-danger-outlined {
  background-color: rgba(197, 42, 26, var(--opacity-3));
  color: #C52A1A;
  box-shadow: 0 0 0 0.0625rem #C52A1A;
}
.ap-badge.ap-badge-warning {
  background-color: #FFBF1F;
  color: #252525;
}
.ap-badge.ap-badge-warning-outlined {
  background-color: rgba(255, 191, 31, var(--opacity-3));
  color: var(--color-text-heading);
  box-shadow: 0 0 0 0.0625rem #FFBF1F;
}
.ap-badge.ap-badge-success {
  background-color: #21812D;
  color: var(--color-background-secondary);
}
.ap-badge.ap-badge-success-outlined {
  background-color: rgba(33, 129, 45, var(--opacity-3));
  color: #21812D;
  box-shadow: 0 0 0 0.0625rem #21812D;
}
.ap-badge.ap-badge-info {
  background-color: #F3F3F3;
  color: #252525;
}
.ap-badge.ap-badge-info-outlined {
  background-color: #F3F3F3;
  color: #474747;
  box-shadow: 0 0 0 0.0625rem #474747;
}

.ap-breadcrumb-ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.ap-breadcrumb {
  display: grid;
  grid-auto-flow: column;
  border-radius: var(--border-radius-3);
}
.ap-breadcrumb .ap-breadcrumb-item {
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  line-height: 1.5rem;
  user-select: none;
  color: var(--color-text-heading);
}
.ap-breadcrumb .ap-breadcrumb-item a,
.ap-breadcrumb .ap-breadcrumb-item a:link,
.ap-breadcrumb .ap-breadcrumb-item a:visited,
.ap-breadcrumb .ap-breadcrumb-item a:hover,
.ap-breadcrumb .ap-breadcrumb-item a:active {
  text-decoration: none;
  color: inherit;
}
.ap-breadcrumb .ap-breadcrumb-item:not(:last-child) {
  font-weight: var(--font-weight-2);
  color: var(--color-text-link-primary);
  cursor: pointer;
}
.ap-breadcrumb .ap-breadcrumb-item .Appkit4-icon {
  cursor: default;
}
.ap-breadcrumb .ap-breadcrumb-item span:not(.Appkit4-icon):hover, .ap-breadcrumb .ap-breadcrumb-item span:not(.Appkit4-icon):focus {
  text-decoration: underline;
}
.ap-breadcrumb .ap-breadcrumb-item:last-child {
  cursor: default;
}
.ap-breadcrumb .ap-breadcrumb-item:last-child a {
  cursor: default;
}
.ap-breadcrumb .ap-breadcrumb-item:last-child span:hover {
  text-decoration: none;
}
.ap-breadcrumb .ap-breadcrumb-item:last-child .icon-right-chevron-outline {
  display: none;
}
.ap-breadcrumb .ap-breadcrumb-item .Appkit4-icon {
  margin-right: var(--spacing-2);
}
.ap-breadcrumb .ap-breadcrumb-item .icon-right-chevron-outline {
  margin-left: var(--spacing-2);
  font-weight: var(--font-weight-2);
  font-size: 0.75rem;
  width: 16px;
  height: 16px;
  line-height: 1rem;
}
.ap-breadcrumb .ap-breadcrumb-item .icon-next-outline {
  margin-right: 0;
  font-size: 0.75rem;
  font-weight: 600;
}

.ap-button {
  height: 2.5rem;
  padding: var(--spacing-3) var(--spacing-7);
  border-radius: var(--border-radius-2);
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  letter-spacing: -0.025rem;
}
.ap-button .ap-button-label {
  letter-spacing: -0.025rem;
  white-space: nowrap;
}
.ap-button.ap-button-compact {
  height: 2rem;
  padding: var(--spacing-2) var(--spacing-7);
}
.ap-button.ap-button-loading {
  min-width: 72px;
}
.ap-button.ap-button-loading.in-loading {
  cursor: default;
}
.ap-button.ap-button-loading.in-loading:focus {
  outline: none;
}
.ap-button.ap-button-loading.in-loading.ap-button-icon .ap-button-loading-icon {
  margin-left: var(--spacing-4);
}
.ap-button.ap-button-loading .label-hidden {
  letter-spacing: -0.025rem;
  opacity: 0;
}
.ap-button.ap-button-loading .ap-button-label {
  position: absolute;
  font-size: 1rem;
  transition: font-size 0.3s;
}
.ap-button.ap-button-loading .ap-button-label.hidden {
  font-size: 0;
}
.ap-button.ap-button-loading .ap-button-loading-icon {
  cursor: default;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  mask-image: url("../images/icon-loading.svg");
  -webkit-mask-image: url("../images/icon-loading.svg");
}
.ap-button.ap-button-loading .ap-button-loading-icon.hidden {
  display: none;
}
.ap-button.ap-button-icon {
  padding: var(--spacing-3) var(--spacing-7) var(--spacing-3) var(--spacing-4);
}
.ap-button.ap-button-icon.ap-button-compact {
  padding: var(--spacing-2) var(--spacing-7) var(--spacing-2) var(--spacing-4);
}
.ap-button.ap-button-primary .ap-button-loading-icon, .ap-button.ap-button-negative .ap-button-loading-icon {
  background-color: #FFFFFF;
}
.ap-button.ap-button-secondary .ap-button-loading-icon, .ap-button.ap-button-text .ap-button-loading-icon {
  background-color: var(--color-background-primary);
}
.ap-button.ap-button-tertiary .ap-button-loading-icon {
  background-color: #474747;
}
.ap-button:not([disabled]).ap-button-primary {
  color: var(--color-text-tertiary);
  background-color: var(--color-background-primary);
}
.ap-button:not([disabled]).ap-button-primary:not(.in-loading):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-primary:not(.in-loading):active::after {
  background-color: rgba(0, 0, 0, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-primary:not(.in-loading):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-button:not([disabled]).ap-button-primary:not(.in-loading):focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-button:not([disabled]).ap-button-secondary {
  color: var(--color-text-primary);
  background-color: transparent;
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-primary);
}
.ap-button:not([disabled]).ap-button-secondary:not(.ap-button-loading):hover {
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-button:not([disabled]).ap-button-secondary:not(.ap-button-loading):active {
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-4));
}
.ap-button:not([disabled]).ap-button-secondary:not(.ap-button-loading):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3)), 0 0 0 0.03125rem var(--color-text-primary), inset 0 0 0 0.03125rem var(--color-text-primary);
}
.ap-button:not([disabled]).ap-button-secondary:not(.ap-button-loading):focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-button:not([disabled]).ap-button-tertiary {
  color: #474747;
  background-color: #E8E8E8;
}
.ap-button:not([disabled]).ap-button-tertiary:not(.in-loading):hover::after {
  background-color: rgba(0, 0, 0, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-tertiary:not(.in-loading):active {
  background-color: #E8E8E8;
  color: #474747;
}
.ap-button:not([disabled]).ap-button-tertiary:not(.in-loading):active::after {
  background-color: rgba(0, 0, 0, var(--opacity-4));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-tertiary:not(.in-loading):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(232, 232, 232, var(--opacity-6));
}
.ap-button:not([disabled]).ap-button-tertiary:not(.in-loading):focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-button:not([disabled]).ap-button-text {
  color: var(--color-text-primary);
  background-color: transparent;
}
.ap-button:not([disabled]).ap-button-text:not(.in-loading):hover {
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-button:not([disabled]).ap-button-text:not(.in-loading):active {
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-4));
}
.ap-button:not([disabled]).ap-button-text:not(.in-loading):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-button:not([disabled]).ap-button-text:not(.in-loading):focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-button:not([disabled]).ap-button-negative {
  color: var(--color-background-secondary);
  background-color: var(--color-background-danger);
}
.ap-button:not([disabled]).ap-button-negative:not(.in-loading):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-negative:not(.in-loading):active::after {
  background-color: rgba(0, 0, 0, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-button:not([disabled]).ap-button-negative:not(.in-loading):focus:not(.keyboard-focus) {
  outline: none;
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-danger-rgb), var(--opacity-3));
}
.ap-button:not([disabled]).ap-button-negative:not(.in-loading):focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-button.ap-button-icon-only {
  padding: var(--spacing-3);
}
.ap-button.ap-button-icon-only:not(.ap-button-loading) {
  border-radius: 1.25rem;
}
.ap-button.ap-button-icon-only.ap-button-compact {
  padding: var(--spacing-2);
}
.ap-button[disabled] {
  cursor: not-allowed;
}
.ap-button[disabled].ap-button-primary {
  color: rgba(var(--color-text-tertiary-rgb), var(--opacity-5));
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-5));
}
.ap-button[disabled].ap-button-secondary {
  color: rgba(var(--color-text-primary-rgb), var(--opacity-5));
  background-color: transparent;
  box-shadow: inset 0 0 0 0.0625rem rgba(var(--color-text-primary-rgb), var(--opacity-5));
}
.ap-button[disabled].ap-button-tertiary {
  color: rgba(71, 71, 71, var(--opacity-5));
  background-color: rgba(232, 232, 232, var(--opacity-5));
}
.ap-button[disabled].ap-button-negative {
  color: rgba(var(--color-background-secondary-rgb), var(--opacity-5));
  background-color: rgba(var(--color-background-danger-rgb), var(--opacity-5));
}
.ap-button[disabled].ap-button-text {
  color: rgba(var(--color-text-primary-rgb), var(--opacity-5));
  background-color: rgba(var(--color-text-body-rgb), 0);
}
.ap-button .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-button:not(.ap-button-icon-only) .Appkit4-icon {
  margin-right: var(--spacing-2);
}
.ap-button:not(.ap-button-icon-only) .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}

.ap-buttons-wrapper {
  border-radius: var(--border-radius-2);
  position: relative;
}
.ap-buttons-wrapper .ap-group-buttons,
.ap-buttons-wrapper .ap-menu-buttons {
  display: flex;
}
.ap-buttons-wrapper .ap-group-buttons .icon-down-chevron-outline,
.ap-buttons-wrapper .ap-menu-buttons .icon-down-chevron-outline {
  transform: rotate(0);
  transition: transform 0.3s;
}
.ap-buttons-wrapper .ap-group-buttons .icon-down-chevron-outline.rotate,
.ap-buttons-wrapper .ap-menu-buttons .icon-down-chevron-outline.rotate {
  transform: rotate(-180deg);
}
.ap-buttons-wrapper .ap-menu-buttons .ap-button {
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-3) var(--spacing-7);
}
.ap-buttons-wrapper .ap-menu-buttons .ap-button.ap-button-compact {
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-7);
}
.ap-buttons-wrapper .ap-menu-buttons .ap-button .icon-down-chevron-outline {
  margin-right: 0;
  margin-left: var(--spacing-2);
}
.ap-buttons-wrapper .ap-dropdown-list {
  width: 320px !important;
  right: 0;
}
.ap-buttons-wrapper div.ap-button-dropdown {
  width: 20rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-3);
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(0, 0, 0, 0.24), 0 0 0.0625rem 0 rgba(0, 0, 0, 0.04);
  background-color: var(--color-background-alt);
  margin-top: var(--spacing-3);
  position: absolute;
  right: var(--spacing-2);
  z-index: 99;
  color: var(--color-text-heading);
}
.ap-buttons-wrapper div.ap-button-dropdown.align-left {
  left: 0;
  right: auto;
}
.ap-buttons-wrapper div.ap-button-dropdown .ap-option-item:last-child {
  margin-bottom: 0;
}
.ap-buttons-wrapper .custom + div.ap-button-dropdown {
  position: absolute;
  display: block;
  padding: var(--spacing-3);
  width: 17.125rem;
  right: 0;
  left: 0;
  border-radius: var(--border-radius-3);
  transition: all 0.3s;
  z-index: 1;
  background-color: var(--color-background-container);
  color: var(--color-text-heading);
  box-shadow: var(--box-shadow-3) !important;
  font-weight: normal;
}
.ap-buttons-wrapper .custom + div.ap-button-dropdown:not(.show) {
  visibility: hidden;
  transform: translateY(var(--spacing-4));
  opacity: 0;
}
.ap-buttons-wrapper .custom + div.ap-button-dropdown.align-left {
  right: auto;
}
.ap-buttons-wrapper .custom + div.ap-button-dropdown.align-right {
  left: auto;
}
.ap-buttons-wrapper .group-left {
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
  padding: var(--spacing-3) var(--spacing-7);
  letter-spacing: -0.025rem;
}
.ap-buttons-wrapper .group-left.ap-button-compact {
  padding: var(--spacing-2) var(--spacing-7);
}
.ap-buttons-wrapper .group-left.ap-button-icon {
  padding: var(--spacing-3) var(--spacing-7) var(--spacing-3) var(--spacing-4);
}
.ap-buttons-wrapper .group-right {
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
  margin-left: var(--spacing-1);
  padding: var(--spacing-3);
}
.ap-buttons-wrapper .group-right.ap-button-compact {
  padding: var(--spacing-2) var(--spacing-3);
}
.ap-buttons-wrapper .group-right .icon-down-chevron-outline {
  font-weight: normal;
  margin-right: 0;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-animation {
  animation: loading 1s infinite linear;
}

.ap-button-dropdown .ap-option-item:focus {
  outline: none;
}
.ap-button-dropdown .ap-option-item.active {
  font-weight: var(--font-weight-2);
}

.ap-btn-group {
  display: flex;
  justify-content: space-between;
}
.ap-btn-group.horizontal {
  flex-direction: row;
}
.ap-btn-group.horizontal.rtl {
  flex-direction: row-reverse;
}
.ap-btn-group.vertical {
  flex-direction: column;
}
.ap-btn-group.vertical.rtl {
  flex-direction: column-reverse;
}

.ap-checkbox-container {
  display: flex;
}

.ap-checkbox {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.ap-checkbox .a-hidden-accessibility {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1.5rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1.5rem;
}
.ap-checkbox .a-hidden-accessibility .ap-checkbox-input {
  cursor: default;
  -webkit-appearance: checkbox;
  padding: initial;
  border: initial;
}
.ap-checkbox .a-hidden-accessibility input {
  transform: scale(0);
}
.ap-checkbox .Appkit4-icon {
  font-weight: var(--font-weight-2);
}
.ap-checkbox .Appkit4-icon:before {
  font-weight: var(--font-weight-2);
}
.ap-checkbox .icon-checkbox-deselected-fill {
  color: var(--color-text-body);
}
.ap-checkbox .icon-checkbox-backdrop-outline {
  display: none;
  color: var(--color-background-secondary);
  width: 24px;
}
.ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-selected-fill {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-background-icon-primary);
  font-size: 1rem;
}
.ap-checkbox .icon-checkbox-backdrop-outline .icon-checkbox-indeterminate-fill {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-background-icon-primary);
  font-size: 1rem;
}
.ap-checkbox.ap-checkbox-checked .icon-checkbox-deselected-fill, .ap-checkbox.ap-checkbox-indeterminate .icon-checkbox-deselected-fill {
  display: none;
}
.ap-checkbox.ap-checkbox-checked .icon-checkbox-backdrop-outline, .ap-checkbox.ap-checkbox-indeterminate .icon-checkbox-backdrop-outline {
  display: inline-flex;
}
.ap-checkbox.ap-checkbox-disabled {
  cursor: not-allowed;
}
.ap-checkbox.ap-checkbox-disabled .ap-checkbox-input {
  cursor: not-allowed;
}
.ap-checkbox.ap-checkbox-disabled .icon-checkbox-deselected-fill {
  opacity: var(--opacity-5);
}
.ap-checkbox.ap-checkbox-readonly .ap-checkbox-input {
  cursor: not-allowed;
}
.ap-checkbox.ap-checkbox-checked.ap-checkbox-disabled .icon-checkbox-backdrop-outline, .ap-checkbox.ap-checkbox-indeterminate.ap-checkbox-disabled .icon-checkbox-backdrop-outline {
  opacity: var(--opacity-5);
}
.ap-checkbox.ap-checkbox-focus {
  outline: auto 2px Highlight; /* Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}

.ap-checkbox + .ap-checkbox-label {
  padding-left: var(--spacing-2);
  line-height: 1.5rem;
  display: block;
  font-size: 1rem;
  color: var(--color-text-body);
  letter-spacing: -0.025rem;
  cursor: pointer;
}
.ap-checkbox + .ap-checkbox-label:empty {
  display: none;
}

.ap-checkbox-disabled + .ap-checkbox-label {
  opacity: var(--opacity-5);
  cursor: not-allowed;
}

.ap-checkbox-readonly + .ap-checkbox-label {
  cursor: not-allowed;
}

.ap-combobox-dropdown-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2001;
}

.ap-combobox-dropdown-overlay-enter {
  opacity: 0;
  transform: translateY(12px);
}

.ap-combobox-dropdown-overlay-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.3s;
}

.ap-combobox-dropdown-overlay-enter-done {
  transform: none;
}

.ap-combobox {
  position: relative;
}
.ap-combobox .ap-combobox-input-box {
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-input {
  width: 100%;
  padding: var(--spacing-4) var(--spacing-8) var(--spacing-4) var(--spacing-3);
  height: 3rem;
  color: var(--color-text-body);
  font-size: 1rem;
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
  background-color: transparent;
  outline: 0 !important;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-input::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-light);
}
.ap-combobox .ap-combobox-input-box .ap-combobox-input.exceedPadding {
  padding-right: 6rem;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon {
  position: absolute;
  top: var(--spacing-2);
  right: 3.3125rem;
  border-radius: var(--border-radius-2);
  visibility: hidden;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  cursor: pointer;
  color: var(--color-text-body);
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline::before {
  font-weight: var(--font-weight-2);
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline.disabled {
  cursor: not-allowed;
  opacity: var(--opacity-5);
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline.readonly {
  cursor: not-allowed;
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline:not(.disabled):hover {
  cursor: pointer;
  border-radius: var(--border-radius-2);
  background-color: var(--color-background-hover-selected);
}
.ap-combobox .ap-combobox-input-box .ap-combobox-clear-icon.showDeleteIcon {
  visibility: visible;
}
.ap-combobox .ap-combobox-input-box .separte-line {
  position: absolute;
  display: inline-block;
  top: 8px;
  right: 46px;
  width: 1px;
  height: 32px;
  border-radius: 0.5px;
  background-color: #d1d1d1;
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline {
  position: absolute;
  right: var(--spacing-2);
  top: var(--spacing-2);
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: var(--border-radius-1);
  transform: rotate(0);
  transition: transform 0.3s;
  color: var(--color-text-body);
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline::before {
  font-weight: var(--font-weight-2);
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline.up {
  transform: rotate(-180deg);
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline.disabled {
  cursor: not-allowed;
  opacity: var(--opacity-5);
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline.readonly {
  cursor: not-allowed;
}
.ap-combobox .ap-combobox-input-box .icon-down-chevron-outline:not(.disabled):hover {
  cursor: pointer;
}
.ap-combobox.disabled {
  cursor: not-allowed;
}
.ap-combobox.disabled .ap-combobox-input-box {
  cursor: not-allowed;
  pointer-events: none;
  box-shadow: inset 0 0 0 0.0625rem rgba(var(--color-background-border-rgb), var(--opacity-6));
}
.ap-combobox.disabled .ap-combobox-input-box .ap-combobox-input {
  cursor: not-allowed;
  color: rgba(var(--color-text-heading-rgb), var(--opacity-5));
}
.ap-combobox.disabled .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline {
  color: rgba(var(--color-text-light-rgb), var(--opacity-5));
}
.ap-combobox.disabled .ap-combobox-input-box .separte-line {
  opacity: var(--opacity-5);
}
.ap-combobox.readonly {
  cursor: not-allowed;
}
.ap-combobox.readonly .ap-combobox-input-box {
  pointer-events: none;
  cursor: not-allowed;
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
}
.ap-combobox.readonly .ap-combobox-input-box .ap-combobox-input {
  pointer-events: none;
  color: var(--color-text-heading);
  opacity: var(--opacity-6);
}
.ap-combobox.readonly .ap-combobox-input-box .ap-combobox-clear-icon {
  opacity: var(--opacity-6);
  pointer-events: none;
}
.ap-combobox.readonly .ap-combobox-input-box .icon-down-chevron-outline {
  opacity: var(--opacity-6);
  pointer-events: none;
}
.ap-combobox.error .ap-combobox-input-box {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error);
}
.ap-combobox.error .ap-combobox-input-box .ap-combobox-input {
  color: var(--color-text-error);
}
.ap-combobox.error .ap-combobox-input-box .ap-combobox-clear-icon .icon-circle-delete-outline {
  color: var(--color-text-error);
}
.ap-combobox.error .ap-combobox-input-box .ap-combobox-clear-icon:hover {
  border-radius: var(--spacing-2);
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
}
.ap-combobox.error .ap-combobox-input-box .icon-down-chevron-outline {
  color: var(--color-text-error);
}
.ap-combobox.error .ap-combobox-input-box .separte-line {
  background-color: var(--color-text-error);
}
.ap-combobox:not(.disabled):not(.readonly) .ap-combobox-input-box:focus-within:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-combobox:not(.disabled):not(.readonly) .ap-combobox-input-box:focus-within.keyboard-focus {
  outline: auto 0.125rem Highlight; /* Firefox */
  outline: auto 0.3125rem -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-combobox:not(.disabled):not(.readonly):not(.error) .ap-combobox-input-box:hover {
  background-color: var(--color-background-hover);
}
.ap-combobox .search-result {
  border: 0;
  clip: rect(0px, 0px, 0px, 0px);
  height: 0.0625rem;
  margin-bottom: -0.0625rem;
  margin-right: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
}

.ap-combobox-dropdown-list-default {
  position: absolute;
  z-index: 99;
}

.ap-combobox-dropdown-list {
  display: block;
  width: 100%;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  color: var(--color-text-body);
  transform: translateY(12px);
  visibility: hidden;
  opacity: 0;
  max-height: 17.5rem;
  overflow: auto;
}
.ap-combobox-dropdown-list.open {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
  pointer-events: auto;
}
.ap-combobox-dropdown-list.hide {
  visibility: hidden;
  animation-fill-mode: both;
  transition: all 0.3s ease-in-out;
}
.ap-combobox-dropdown-list .ap-checkbox-label {
  display: inline-flex;
}

.ap-combobox .ap-combobox-input-box .ap-tag.ap-tag-default.ap-tag-sm.combobox-tag {
  margin-left: var(--spacing-3);
  background-color: var(--color-background-hover-selected);
  color: var(--color-text-body);
  width: max-content;
}
.ap-combobox .ap-combobox-input-box .ap-tag.ap-tag-default.ap-tag-sm.combobox-tag .ap-tag-label {
  font-weight: normal;
}

.ap-combox-custom-wrapper {
  display: flex;
}
.ap-combox-custom-wrapper.show-text {
  padding-left: var(--spacing-3);
}

.ap-drawer-wrapper {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  opacity: 0;
  transition: opacity 0.5s;
  z-index: -1;
}
.ap-drawer-wrapper.masked {
  backdrop-filter: blur(2px);
  background-color: rgba(var(--color-background-default-inverse-rgb), var(--opacity-5));
}
.ap-drawer-wrapper:not(.masked):not(.freeze) {
  pointer-events: none;
}
.ap-drawer-wrapper.visible {
  opacity: 1;
  z-index: 2000;
}
.ap-drawer-wrapper.visible .ap-drawer {
  transform: translate(0, 0) !important;
}
.ap-drawer-wrapper .ap-drawer {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-container);
  transition: transform 0.5s;
  overflow: visible;
  pointer-events: auto;
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-top, .ap-drawer-wrapper .ap-drawer.ap-drawer-bottom {
  height: 300px;
  width: 100%;
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-right, .ap-drawer-wrapper .ap-drawer.ap-drawer-left {
  width: 568px;
  height: 100%;
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-top {
  top: 0;
  border-radius: 0 0 0.75rem 0.75rem;
  box-shadow: var(--box-shadow-3);
  transform: translateY(-100%);
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-right {
  right: 0;
  border-radius: 0.75rem 0 0 0.75rem;
  box-shadow: var(--box-shadow-3);
  transform: translateX(100%);
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-bottom {
  bottom: 0;
  border-radius: 0.75rem 0.75rem 0 0;
  box-shadow: 0 -0.5rem 1rem -0.125rem rgba(var(--color-background-tertiary-rgb), var(--opacity-4));
  transform: translateY(100%);
}
.ap-drawer-wrapper .ap-drawer.ap-drawer-left {
  left: 0;
  border-radius: 0 0.75rem 0.75rem 0;
  box-shadow: 0 -0.5rem 1rem -0.125rem rgba(var(--color-background-tertiary-rgb), var(--opacity-4));
  transform: translateX(-100%);
}
.ap-drawer-wrapper .ap-drawer-header {
  display: flex;
  height: 2rem;
  padding: var(--spacing-6);
  box-sizing: content-box;
  align-items: center;
  overflow: hidden;
}
.ap-drawer-wrapper .ap-drawer-header .ap-drawer-title {
  font-size: var(--spacing-7);
  font-weight: var(--font-weight-2);
  line-height: 2rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
  margin-right: auto;
}
.ap-drawer-wrapper .ap-drawer-header .ap-drawer-action {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: var(--spacing-2);
  padding: var(--spacing-3);
  background-color: transparent;
}
.ap-drawer-wrapper .ap-drawer-header .ap-drawer-action:hover {
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
  cursor: pointer;
}
.ap-drawer-wrapper .ap-drawer-header .ap-drawer-actions .ap-drawer-action:last-of-type {
  margin-right: -0.5rem;
}
.ap-drawer-wrapper .ap-drawer-header .ap-badge {
  margin-right: var(--spacing-3);
}
.ap-drawer-wrapper .ap-drawer-body {
  flex: 1;
  padding: 0 var(--spacing-6);
  overflow-y: auto;
}
.ap-drawer-wrapper .ap-drawer-footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-6);
  overflow: hidden;
}
.ap-drawer-wrapper .ap-drawer-footer .ap-button {
  margin-right: var(--spacing-3);
}
.ap-drawer-wrapper .ap-drawer-footer .ap-button:last-of-type {
  margin-right: 0;
}

.ap-resizer {
  position: absolute;
}
.ap-resizer.ap-resizer-top, .ap-resizer.ap-resizer-bottom {
  left: 5px;
  width: calc(100% - 10px);
  height: 10px;
  cursor: row-resize;
}
.ap-resizer.ap-resizer-right, .ap-resizer.ap-resizer-left {
  top: 5px;
  width: 10px;
  height: calc(100% - 10px);
  cursor: col-resize;
}
.ap-resizer.ap-resizer-top {
  top: -5px;
}
.ap-resizer.ap-resizer-right {
  right: -5px;
}
.ap-resizer.ap-resizer-bottom {
  bottom: -5px;
}
.ap-resizer.ap-resizer-left {
  left: -5px;
}

.ap-dropdown .ap-dropdown-select.error:not(.disabled):not(.readonly):focus .ap-field-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error), 0 0 0 0.25rem rgba(var(--color-text-error-rgb), var(--opacity-3));
}

.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus .ap-field-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}

.ap-dropdown-custom-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  padding-left: var(--spacing-3);
}
.ap-dropdown-custom-wrapper .ap-dropdown-custom-input {
  width: 100%;
  padding: var(--spacing-4) var(--spacing-8) var(--spacing-4) 0;
  height: 3rem;
  color: var(--color-text-body);
  font-size: 1rem;
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
  background-color: transparent;
  outline: 0 !important;
  cursor: pointer;
}

.ap-dropdown-overlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2001;
}

.ap-dropdown-overlay-enter {
  opacity: 0;
  transform: translateY(12px);
}

.ap-dropdown-overlay-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 0.3s, opacity 0.3s;
}

.ap-dropdown-overlay-enter-done {
  transform: none;
}

.ap-dropdown-overlay-exit {
  opacity: 1;
}

.ap-dropdown-overlay-exit-active {
  opacity: 0;
  transform: translateY(12px);
  transition: all 0.3s ease-in-out;
}

.ap-dropdown-list > .ap-option-item:last-child {
  margin-bottom: 0;
}

.ap-dropdown .ap-field-prefix-wrapper .icon-search-outline {
  color: var(--color-text-light);
}

.ap-field-wrapper:hover:not(.disabled) {
  background-color: var(--color-background-hover);
}

.ap-dropdown-list .ap-checkbox-label {
  display: inline-flex;
}

.ap-dropdown-list .ap-dropdown-select-all {
  margin-bottom: var(--spacing-3);
}

.ap-dropdown {
  position: relative;
}
.ap-dropdown .ap-dropdown-select {
  position: relative;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
}
.ap-dropdown .ap-dropdown-select .ap-field {
  width: 100% !important;
}
.ap-dropdown .ap-dropdown-select .ap-field .ap-field-container .ap-field-wrapper .ap-field-input {
  padding-right: 2.75rem !important;
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus {
  outline: none;
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus .ap-field-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus .ap-field-wrapper.keyboard-focus {
  outline: auto 0.125rem Highlight; /* Firefox */
  outline: auto 0.3125rem -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus-within {
  outline: none;
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus-within .ap-dropdown-badge-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-dropdown .ap-dropdown-select:not(.disabled):not(.readonly):focus-within .ap-dropdown-badge-wrapper.keyboard-focus {
  outline: auto 0.125rem Highlight; /* Firefox */
  outline: auto 0.3125rem -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-dropdown .ap-dropdown-select:focus:not(.error) {
  outline: none;
}
.ap-dropdown .ap-dropdown-select:focus:not(.error) .ap-field-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-dropdown .ap-dropdown-select:focus:not(.error) .ap-field-wrapper.keyboard-focus {
  outline: auto 0.125rem Highlight;
  /* Firefox */
  outline: auto 0.3125rem -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-dropdown .ap-dropdown-select .icon-down-chevron-outline {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  margin-top: var(--spacing-4);
  margin-right: var(--spacing-4);
  transform: rotate(0);
  transition: transform 0.3s;
  color: var(--color-text-body);
}
.ap-dropdown .ap-dropdown-select .icon-down-chevron-outline::before {
  font-weight: var(--font-weight-2);
}
.ap-dropdown .ap-dropdown-select .icon-down-chevron-outline.up {
  transform: rotate(-180deg);
}
.ap-dropdown .ap-dropdown-select .icon-down-chevron-outline.disabled {
  cursor: not-allowed;
  opacity: var(--opacity-5);
  pointer-events: none;
}
.ap-dropdown .ap-dropdown-select.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.ap-dropdown.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.ap-dropdown-list.hide .search-container {
  display: block;
  margin-bottom: var(--spacing-3);
}

.ap-dropdown-list.open .search-container {
  display: block;
  margin-bottom: var(--spacing-3);
  pointer-events: auto;
}

.ap-dropdown-list.ap-dropdown-list-portal {
  visibility: visible;
  opacity: 1;
  transform: none;
}

.ap-dropdown-list {
  display: block;
  padding: var(--spacing-3) !important;
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  color: var(--color-text-body);
  max-height: 17.5rem;
  overflow: auto;
}
.ap-dropdown-list.ap-popup {
  overflow: none;
  max-height: none;
}
.ap-option-item {
  position: relative;
  height: 3rem;
  padding: var(--spacing-4) var(--spacing-3) var(--spacing-4) var(--spacing-3);
  border-radius: var(--border-radius-2);
  cursor: pointer;
  display: flex;
  align-items: center;
}
.ap-option-item.noResult {
  justify-content: center;
}
.ap-option-item.noResult:hover {
  cursor: auto;
  background-color: var(--color-background-container);
}

.ap-option-group {
  display: inline-block;
  width: 100%;
  height: 28px;
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-2) 0 var(--spacing-2) var(--spacing-3);
  border-radius: var(--border-radius-2);
  background-color: var(--color-background-hover);
}
.ap-option-group .ap-group-label {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025rem;
}

.ap-option-item {
  position: relative;
  width: 100%;
  height: 3rem;
  padding: var(--spacing-4) var(--spacing-3);
  border-radius: var(--border-radius-2);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-2);
  line-height: 1.5rem;
  color: var(--color-text-body);
}
.ap-option-item .ap-option-left {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ap-option-item .ap-option-left .ap-checkbox-label {
  margin-left: calc(-1 * var(--spacing-2));
  margin-right: var(--spacing-2);
}
.ap-option-item .ap-option-left .ap-option-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.ap-option-item .ap-option-left .ap-option-prefix {
  margin-left: var(--spacing-2);
  margin-right: var(--spacing-2);
}
.ap-option-item .ap-option-left.overflow-options .ap-option-label {
  margin-left: 8px;
}
.ap-option-item .ap-option-desc {
  flex-shrink: 0;
  letter-spacing: -0.025rem;
  max-width: 46%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-text-light);
}
.ap-option-item .ap-option-switch {
  opacity: var(--opacity-7);
  position: absolute;
  right: 0;
}
.ap-option-item:hover:not(.disabled) {
  background-color: var(--color-background-hover);
}
.ap-option-item.moved:not(.disabled) {
  background-color: var(--color-background-hover);
  box-shadow: 0 0 0 0.0625rem var(--color-background-tertiary);
}
.ap-option-item.moved:not(.disabled):hover {
  background-color: var(--color-background-hover-selected);
}
.ap-option-item.active {
  background-color: var(--color-background-selected);
}
.ap-option-item.active .ap-option-label {
  font-weight: var(--font-weight-2);
}
.ap-option-item.active:hover {
  background-color: var(--color-background-hover-selected);
}
.ap-option-item.disabled {
  cursor: not-allowed;
}
.ap-option-item.disabled .ap-option-label {
  opacity: var(--opacity-5);
  cursor: not-allowed;
}
.ap-option-item.disabled .Appkit4-icon {
  opacity: var(--opacity-5);
}
.ap-option-item.noResult {
  justify-content: center;
}
.ap-option-item.noResult:hover {
  cursor: auto;
  background-color: var(--color-background-container);
}
.ap-option-item .icon-arrow-increase-small-outline {
  display: none;
  position: absolute;
  right: var(--spacing-4);
  top: var(--spacing-4);
  cursor: pointer;
}
.ap-option-item.iconLink .icon-arrow-increase-small-outline {
  display: inline-block;
}
.ap-option-item .recent-delete-icon {
  position: absolute;
  display: inline-flex;
  right: 0;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  letter-spacing: -0.025rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-1);
  padding: var(--spacing-3);
}
.ap-option-item .recent-delete-icon .Appkit4-icon {
  cursor: pointer;
  font-size: 1rem;
}
.ap-option-item .recent-delete-icon .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-option-item .recent-delete-icon:hover {
  cursor: pointer;
  background-color: var(--color-background-hover-selected);
}
.ap-option-item:focus-visible {
  outline: none;
}

.ap-feeds-comments-body .ap-field.textarea .ap-field-container .ap-field-wrapper textarea {
  resize: none !important;
}

.ap-feeds-comments {
  position: relative;
  padding: var(--spacing-6);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  user-select: none;
}
.ap-feeds-comments-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ap-feeds-comments-header-title {
  font-size: 1.25rem;
  font-weight: var(--font-weight-2);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
  word-break: break-word;
}
.ap-feeds-comments-body {
  height: 7rem;
  margin: var(--spacing-6) 0;
}
.ap-feeds-comments-footer-wrapper {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: var(--spacing-2);
  margin: calc(-1 * var(--spacing-2));
}
.ap-feeds-comments-footer-wrapper-attach {
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-2);
  background-color: var(--color-background-container);
  cursor: pointer;
  color: var(--color-text-body);
  position: relative;
}
.ap-feeds-comments-footer-wrapper-attach:hover {
  background-color: var(--color-background-hover);
}
.ap-feeds-comments-footer-wrapper-attach input[type=file] {
  cursor: pointer;
  text-indent: 2.625rem;
  width: 2.5rem;
  position: absolute;
  height: 2.5rem;
  top: 0;
  left: 0;
  overflow: hidden;
}
.ap-feeds-comments-footer-wrapper-buttons {
  display: flex;
}
.ap-feeds-comments-footer-wrapper-buttons .footer-button {
  margin-right: var(--spacing-3);
}
.ap-feeds-comments .uploaded-images {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: repeat(4, 7.4375rem);
  margin-top: var(--spacing-6);
  position: relative;
}
.ap-feeds-comments .uploaded-images-placeholder {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
}
.ap-feeds-comments .uploaded-images-placeholder-item {
  width: 7.4375rem;
  height: 7.5rem;
  border-radius: var(--border-radius-2);
}
.ap-feeds-comments .uploaded-images-placeholder-item.rimless {
  border: dashed 0.0625rem #696969;
}
.ap-feeds-comments .uploaded-images-placeholder .uploaded-images-placeholder-item + .uploaded-images-placeholder-item {
  margin-left: var(--spacing-3);
}
.ap-feeds-comments .uploaded-images .image-wrapper {
  width: 7.4375rem;
  height: 7.5rem;
  border-radius: var(--border-radius-2);
  position: relative;
}
.ap-feeds-comments .uploaded-images .image-wrapper img {
  border-radius: var(--border-radius-2);
  height: 100%;
  width: 100%;
}
.ap-feeds-comments .uploaded-images .image-wrapper .Appkit4-icon {
  border-radius: var(--border-radius-1);
  color: #FFFFFF;
  cursor: pointer;
  position: absolute;
  top: var(--spacing-1);
  right: var(--spacing-1);
}
.ap-feeds-comments .uploaded-images .image-wrapper .Appkit4-icon:hover, .ap-feeds-comments .uploaded-images .image-wrapper .Appkit4-icon:focus {
  background-color: rgba(37, 37, 37, var(--opacity-6));
}
.ap-feeds-comments .ap-comment-header-action {
  position: absolute;
  top: var(--spacing-4);
  right: var(--spacing-4);
  display: flex;
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action {
  display: flex;
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .more,
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-2);
  text-align: center;
  line-height: 2.5rem;
  cursor: pointer;
  background-color: var(--color-background-container);
  padding-top: 0;
  padding-bottom: 0;
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .more {
  margin-right: var(--spacing-2);
  position: relative;
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .more:hover {
  background-color: var(--color-background-hover);
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .more .Appkit4-icon {
  color: var(--color-text-heading);
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .close:hover {
  background-color: var(--color-background-hover);
}
.ap-feeds-comments .ap-comment-header-action .ap-feeds-comments-header-action .close .Appkit4-icon {
  color: var(--color-text-heading);
}

.comments-wrapper {
  width: 100%;
  padding: var(--spacing-6);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-1);
  background-color: var(--color-background-container);
}
.comments-wrapper .comments + .comments {
  margin-top: var(--spacing-6);
}
.comments-wrapper .comments {
  display: flex;
  align-items: flex-start;
}
.comments-wrapper .comments .comments-detail .comments-info {
  align-items: baseline;
  display: inline-flex;
  padding: var(--spacing-3) 0 0 var(--spacing-4);
}
.comments-wrapper .comments .comments-detail .comments-info .comments-name {
  margin-right: var(--spacing-4);
  color: var(--color-text-heading);
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
}
.comments-wrapper .comments .comments-detail .comments-info .comments-time {
  color: var(--color-text-light);
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
}
.comments-wrapper .comments .comments-detail .comments-content {
  margin: var(--spacing-2) 0 0 var(--spacing-4);
  word-break: break-word;
  color: var(--color-text-body);
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
}
.comments-wrapper .comments .comments-detail .comments-images {
  display: grid;
  grid-template-columns: 12.5rem 12.5rem;
  padding-left: var(--spacing-4);
  margin-top: var(--spacing-3);
  margin-bottom: calc(-1 * var(--spacing-2));
}
.comments-wrapper .comments .comments-detail .comments-images .image {
  align-items: center;
  display: flex;
  height: 8rem;
  justify-content: center;
  width: 12.5rem;
}
.comments-wrapper .comments .comments-detail .comments-images .image:focus {
  border: solid 0.125rem #252525;
  border-radius: var(--border-radius-3);
  padding: var(--spacing-2);
}
.comments-wrapper .comments .comments-detail .comments-images .image:focus-visible {
  outline: none;
}
.comments-wrapper .comments .comments-detail .comments-images .image img {
  height: 7.5rem;
  width: 12rem;
}
.comments-wrapper .comments .comments-detail .comments-likes {
  display: flex;
  margin-top: var(--spacing-4);
  margin-left: var(--spacing-2);
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action {
  cursor: pointer;
  height: 2.5rem;
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-3) var(--spacing-3);
  border-radius: var(--border-radius-2);
  background-color: transparent;
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action .Appkit4-icon {
  color: var(--color-text-heading);
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action:hover {
  background-color: var(--color-background-hover);
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action-text {
  margin: 0 0 0 var(--spacing-2);
  color: var(--color-text-heading);
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
}
.comments-wrapper .comments .comments-detail .comments-likes .comments-action + .comments-action {
  margin-left: var(--spacing-2);
}

.backBox {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(17, 17, 17, var(--opacity-5));
  backdrop-filter: var(--blur-1);
  z-index: 1000;
  top: 0;
  left: 0;
}

.bigImg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown > .ap-option-item:last-child {
  margin-bottom: 0;
}

.ap-field.readonly .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group.with-unit:not(.disabled):hover {
  background: none !important;
}

.ap-field-prefix-wrapper {
  display: flex;
  align-items: center;
  width: 2.5rem;
  height: 3rem;
  justify-content: center;
}

.ap-field-suffix-wrapper {
  display: flex;
  align-items: center;
  height: 3rem;
  justify-content: center;
  margin-right: var(--spacing-2);
  position: absolute;
  top: 0;
  right: 0;
}

.ap-field-dropdown-phone .ap-option-item .ap-checkbox-label .ap-option-prefix {
  display: flex;
  margin: 0;
}

.ap-field-dropdown-phone .ap-option-item .ap-checkbox-label {
  margin: 0;
}

.ap-field .ap-field-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.ap-field .ap-field-container:not(.open) .ap-datepicker-wrapper {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}
.ap-field .ap-field-container .ap-field-wrapper {
  display: flex;
  height: 3rem;
  width: 100%;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  overflow: hidden;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
}
.ap-field .ap-field-container .ap-field-wrapper .icon-down-chevron-outline::before {
  transform: rotate(0);
  transition: transform 0.3s;
}
.ap-field .ap-field-container .ap-field-wrapper .icon-down-chevron-outline.up::before {
  transform: rotate(-180deg);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-input {
  letter-spacing: -0.025rem;
  width: 100%;
  color: var(--color-text-heading);
  -webkit-appearance: none;
  background-color: transparent;
  outline: 0 !important;
  transition: margin 0.3s, padding-bottom 0.3s, color 0.3s;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-input.shorter-input {
  padding-right: 44px;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--color-text-body);
}
.ap-field .ap-field-container .ap-field-wrapper input {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 0 9px;
}
.ap-field .ap-field-container .ap-field-wrapper input[type=password]::-webkit-contacts-auto-fill-button, .ap-field .ap-field-container .ap-field-wrapper input[type=password]::-webkit-credentials-auto-fill-button, .ap-field .ap-field-container .ap-field-wrapper input[type=password]::-ms-clear, .ap-field .ap-field-container .ap-field-wrapper input[type=password]::-ms-reveal {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}
.ap-field .ap-field-container .ap-field-wrapper input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}
.ap-field .ap-field-container .ap-field-wrapper input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-icon-btn {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  color: var(--color-text-heading);
  border-radius: var(--border-radius-1);
  transition: all 0.3s;
  cursor: pointer;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-icon-btn:not(.disabled):not(.icon-down-chevron-outline):hover {
  background-color: var(--color-background-hover-selected);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-icon-btn.disabled {
  opacity: var(--opacity-3);
  pointer-events: none;
  color: var(--color-text-body);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-icon-btn.readonly {
  opacity: var(--opacity-6);
  pointer-events: none;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-icon-btn::before {
  font-weight: var(--font-weight-2);
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper {
  display: flex;
  align-items: center;
  margin-right: var(--spacing-2);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group {
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-1);
  transition: all 0.3s;
  cursor: pointer;
  z-index: 1;
  color: var(--color-text-heading);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group:not(.disabled):hover {
  background-color: var(--color-background-hover-selected);
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group.disabled {
  opacity: var(--opacity-3);
  pointer-events: none;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group .ap-field-img {
  margin-right: var(--spacing-2);
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  user-select: none;
  transition: all 0.3s;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group .ap-field-icon-btn {
  position: relative;
  top: auto;
  right: auto;
  width: 1.5rem;
  height: 1.5rem;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown-button-group .ap-field-unit {
  margin-right: var(--spacing-1);
  line-height: 1.5rem;
  pointer-events: none;
  user-select: none;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-wrapper .ap-field-dropdown {
  position: absolute;
  right: var(--spacing-3);
  top: calc(var(--spacing-8) - var(--spacing-3));
  width: 17.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-3);
  box-shadow: var(--level-3);
  background-color: var(--color-background-container);
  z-index: 99;
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
  overflow: auto;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-counter {
  position: absolute;
  top: var(--spacing-3);
  right: var(--spacing-3);
  height: 0.75rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
  color: var(--color-text-light);
  transition: opacity 0.3s, color 0.3s;
  pointer-events: none;
}
.ap-field .ap-field-container .ap-field-wrapper .ap-field-dropdown-phone-tag {
  position: absolute;
  left: var(--spacing-3);
  top: var(--spacing-7);
  height: 1rem;
  line-height: 1rem;
  user-select: none;
  cursor: default;
  transition: all 0.3s;
  color: var(--color-text-heading);
}
.ap-field .ap-field-container.hide-title input {
  margin: var(--spacing-4) var(--spacing-3);
  padding: 0;
}
.ap-field .ap-field-container.hide-title textarea {
  margin-top: var(--spacing-5) !important;
}
.ap-field .ap-field-container .ap-field-datepicker-error-message-container {
  position: absolute;
  top: 3rem;
  left: 0;
}
.ap-field .ap-field-container .ap-field-datepicker-error-message-container .ap-field-datepicker-error-message {
  margin: var(--spacing-3) var(--spacing-3) var(--spacing-4);
  color: var(--color-text-error);
  font-size: 0.75rem;
  height: 0.875rem;
}
.ap-field .ap-field-container .ap-field-content .ap-datepicker-options {
  display: none;
}
.ap-field .ap-field-container:not(.show) .ap-field-dropdown-wrapper .ap-field-dropdown {
  visibility: hidden;
  opacity: 0;
  transform: translateY(var(--spacing-4));
}
.ap-field .ap-field-title-container {
  position: absolute;
  left: var(--spacing-3);
  top: var(--spacing-4);
  max-width: calc(100% - var(--spacing-5));
  line-height: 1.5rem;
  font-size: 1rem;
  color: var(--color-text-light);
  transition: all 0.3s;
  pointer-events: none;
  z-index: 1;
}
.ap-field .ap-field-title-container .ap-field-title {
  width: 100%;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ap-field .ap-field-title-container.required::before {
  content: "";
  position: absolute;
  right: calc(-1 * var(--spacing-3));
  top: var(--spacing-2);
  width: 0.25rem;
  height: 0.25rem;
  border-radius: var(--border-radius-1);
  background-color: var(--color-text-error);
  transition: all 0.3s;
}
.ap-field.has-length .ap-field-title-container, .ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .ap-field-title-container, .ap-field:not(.readonly) .ap-field-container.open .ap-field-title-container, .ap-field:not(.readonly) .ap-field-container.show .ap-field-title-container {
  position: absolute;
  left: var(--spacing-3);
  top: var(--spacing-3);
  max-width: calc(100% - var(--spacing-5));
  height: 0.875rem;
  line-height: 0.875rem;
  margin-top: -1px;
  font-size: 0.75rem;
  color: var(--color-text-light);
  pointer-events: none;
}
.ap-field.has-length .ap-field-title-container.required::before, .ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .ap-field-title-container.required::before, .ap-field:not(.readonly) .ap-field-container.open .ap-field-title-container.required::before, .ap-field:not(.readonly) .ap-field-container.show .ap-field-title-container.required::before {
  content: "";
  position: absolute;
  right: calc(-1 * var(--spacing-2));
  top: var(--spacing-1);
  width: 0.125rem;
  height: 0.125rem;
  background-color: var(--color-text-error);
  border-radius: 50%;
}
.ap-field:not(.disabled):not(.readonly):not(.text-readonly):focus-within .hide-title .ap-field-title-container, .ap-field:not(.readonly) .ap-field-container.show.hide-title .ap-field-title-container {
  transition: opacity 0;
  left: var(--spacing-3);
  top: var(--spacing-4);
  max-width: calc(100% - var(--spacing-5));
  line-height: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
}
.ap-field.has-length .ap-field-placeholder-container {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  transform: translateY(-0.375rem);
}
.ap-field.has-length .hide-title .ap-field-title-container {
  opacity: 0;
  transition: none;
}
.ap-field:not(.has-length) .ap-field-title-container {
  transition: all 0.3s;
}
.ap-field:not(.has-length) .ap-field-placeholder-container {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  left: var(--spacing-3);
  top: var(--spacing-7);
  transition: opacity 0.3s, transform 0.3s;
}
.ap-field:not(.has-length):not(.readonly) .ap-field-container.open .ap-field-placeholder-container, .ap-field:not(.has-length):not(.readonly) .ap-field-container.show .ap-field-placeholder-container, .ap-field:not(.has-length):focus-within .ap-field-placeholder-container {
  opacity: 1;
  visibility: visible;
  position: absolute;
  left: var(--spacing-3);
  top: 1.875rem;
  max-width: calc(100% - var(--spacing-5));
  height: 1rem;
  line-height: 1rem;
  font-size: 1rem;
  color: var(--color-text-light);
  transition: opacity 0.3s, transform 0.3s;
  transform: translateY(-0.375rem);
}
.ap-field:not(.has-length):not(.readonly) .ap-field-container.open .ap-field-placeholder-container.has-no-title, .ap-field:not(.has-length):not(.readonly) .ap-field-container.show .ap-field-placeholder-container.has-no-title, .ap-field:not(.has-length):focus-within .ap-field-placeholder-container.has-no-title {
  top: var(--spacing-7);
}
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.hide-title) input.ap-field-input {
  margin-top: 1.875rem;
  padding-bottom: 0.3125rem;
}
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.hide-title) textarea.ap-field-input {
  margin-top: 2rem !important;
  margin-bottom: 0 !important;
}
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .icon-down-chevron-outline:not(.with-unit),
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-password-reveal-icon,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .icon-circle-delete-outline,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-img,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-icon,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-dropdown-phone-tag,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-counter,
.ap-field:not(.has-length):not(:focus-within) .ap-field-container:not(.show):not(.open):not(.ap-inputnumber-container) .ap-field-dropdown-button-group:not(.with-unit) {
  transform: translateY(0.25rem);
  opacity: 0;
  visibility: hidden;
}
.ap-field.textarea .ap-field-container::after {
  content: "";
  position: absolute;
  right: 0.1875rem;
  bottom: 0.1875rem;
  width: 0.1875rem;
  height: 0.1875rem;
  border-radius: 50%;
  background-color: var(--color-background-border);
  transition: background-color 0.3s, opacity 0.3s;
  pointer-events: none;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper {
  height: auto;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper textarea {
  margin: var(--spacing-7) 0 var(--spacing-3) var(--spacing-3);
  padding: 0;
  height: 5rem;
  min-height: 1.5rem;
  line-height: 1.25rem;
  resize: vertical;
  overflow-y: scroll;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper textarea::-webkit-resizer {
  display: none;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper textarea::-webkit-scrollbar {
  background-color: transparent;
  width: 0.5rem;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper textarea::-webkit-scrollbar-corner {
  background-color: transparent;
}
.ap-field.textarea .ap-field-container .ap-field-wrapper textarea::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.ap-field.textarea.disabled textarea {
  overflow: hidden !important;
}
.ap-field.textarea.readonly textarea {
  cursor: not-allowed;
}
.ap-field:not(.disabled):not(.readonly):not(.text-readonly) .ap-field-wrapper:not(.as-toggle) {
  cursor: text;
}
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-wrapper:hover {
  background-color: var(--color-background-hover);
}
.ap-field:not(.disabled):not(.readonly):not(.error):focus-within .ap-field-wrapper.focus:not(.keyboard-focus),
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.show .ap-field-wrapper.focus:not(.keyboard-focus),
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.open .ap-field-wrapper.focus:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-field:not(.disabled):not(.readonly):not(.error):focus-within .ap-field-wrapper.focus.keyboard-focus,
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.show .ap-field-wrapper.focus.keyboard-focus,
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.open .ap-field-wrapper.focus.keyboard-focus {
  outline: auto 5px #2474d6;
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-field:not(.disabled):not(.readonly):not(.error):focus-within .ap-field-container::after,
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.show .ap-field-container::after,
.ap-field:not(.disabled):not(.readonly):not(.error) .ap-field-container.open .ap-field-container::after {
  background-color: var(--color-background-primary);
}
.ap-field:not(.disabled).error:focus-within .ap-field-wrapper:not(.keyboard-focus),
.ap-field:not(.disabled).error .ap-field-container.show .ap-field-wrapper:not(.keyboard-focus),
.ap-field:not(.disabled).error .ap-field-container.open .ap-field-wrapper:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error), 0 0 0 0.25rem rgba(var(--color-text-error-rgb), var(--opacity-3));
}
.ap-field:not(.disabled).error:focus-within .ap-field-wrapper.focus.keyboard-focus,
.ap-field:not(.disabled).error .ap-field-container.show .ap-field-wrapper.focus.keyboard-focus,
.ap-field:not(.disabled).error .ap-field-container.open .ap-field-wrapper.focus.keyboard-focus {
  outline: auto 5px #2474d6;
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-field.error .ap-field-wrapper {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error);
}
.ap-field.error .ap-field-wrapper .ap-field-input {
  color: var(--color-text-error);
}
.ap-field.error .ap-field-wrapper .ap-field-input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text-error);
}
.ap-field.error .ap-field-wrapper .ap-field-counter {
  color: var(--color-text-error);
}
.ap-field.error .ap-field-wrapper .ap-field-icon-btn {
  color: var(--color-text-error);
}
.ap-field.error .ap-field-wrapper .ap-field-icon-btn:not(.disabled):not(.icon-down-chevron-outline):hover {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3)) !important;
}
.ap-field.error .ap-field-title-container .ap-field-title {
  color: var(--color-text-error);
}
.ap-field.error .ap-field-container::after {
  background-color: var(--color-text-error);
}
.ap-field.error .ap-inputnumber-container .icon-up-chevron-outline, .ap-field.error .ap-inputnumber-container .icon-down-chevron-outline {
  color: var(--color-text-error);
}
.ap-field.disabled {
  cursor: not-allowed;
}
.ap-field.disabled .ap-field-container {
  pointer-events: none;
}
.ap-field.disabled .ap-field-container .ap-field-wrapper {
  box-shadow: inset 0 0 0 0.0625rem rgba(var(--color-background-border-rgb), var(--opacity-6));
}
.ap-field.disabled .ap-field-container .ap-field-wrapper .ap-field-input {
  color: rgba(var(--color-text-heading-rgb), var(--opacity-5));
}
.ap-field.disabled .ap-field-container .ap-field-title-container {
  color: rgba(var(--color-text-light-rgb), var(--opacity-5));
}
.ap-field.disabled .ap-field-container .ap-field-icon-btn,
.ap-field.disabled .ap-field-container .ap-field-password-reveal-icon:not(.disabled),
.ap-field.disabled .ap-field-container .ap-field-unit,
.ap-field.disabled .ap-field-container .icon-calendar-outline,
.ap-field.disabled .ap-field-container .ap-field-dropdown-phone-tag,
.ap-field.disabled .ap-field-container .ap-field-counter,
.ap-field.disabled .ap-field-container [ap-tooltip],
.ap-field.disabled .ap-field-container [data-tooltip=true] {
  color: rgba(var(--color-text-light-rgb), var(--opacity-5));
}
.ap-field.disabled .ap-field-container::after {
  color: rgba(var(--color-background-border-rgb), var(--opacity-6));
}
.ap-field.disabled .ap-field-container .ap-field-img {
  opacity: var(--opacity-3);
}
.ap-field.disabled .ap-inputnumber-container .icon-up-chevron-outline, .ap-field.disabled .ap-inputnumber-container .icon-down-chevron-outline {
  color: rgba(var(--color-text-light-rgb), var(--opacity-5));
}
.ap-field.readonly {
  cursor: not-allowed;
}
.ap-field.readonly .ap-field-container .ap-field-wrapper {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
}
.ap-field.readonly .ap-field-container .ap-field-wrapper .ap-field-input {
  pointer-events: none;
  color: var(--color-text-heading);
  opacity: var(--opacity-6);
}
.ap-field.readonly .ap-field-container .ap-field-password-reveal-icon:not(.disabled),
.ap-field.readonly .ap-field-container .ap-field-dropdown-button-group,
.ap-field.readonly .ap-field-container .icon-calendar-outline,
.ap-field.readonly .ap-field-container .ap-field-dropdown-phone-tag,
.ap-field.readonly .ap-field-container [ap-tooltip]::before,
.ap-field.readonly .ap-field-container [data-tooltip=true]::before {
  opacity: var(--opacity-6);
}
.ap-field.readonly .ap-field-container .ap-field-icon-btn[ap-tooltip]:hover,
.ap-field.readonly .ap-field-container .ap-field-icon-btn[data-tooltip=true]:hover {
  background-color: var(--color-background-hover) !important;
}
.ap-field.readonly:not(.has-length) .ap-field-container:not(.show) .ap-field-dropdown-phone-tag {
  transform: translateY(0.375rem);
  opacity: 0;
  visibility: hidden;
}
.ap-field.readonly:not(.has-length) .ap-field-container:not(.show) .ap-field-dropdown-phone-tag ~ .ap-field-dropdown-wrapper .ap-field-dropdown-button-group {
  opacity: 0 !important;
}
.ap-field.readonly:not(.has-length) .ap-field-container:not(.show) .ap-field-password-reveal-icon,
.ap-field.readonly:not(.has-length) .ap-field-container:not(.show) .ap-field-counter,
.ap-field.readonly:not(.has-length) .ap-field-container:not(.show) .ap-field-placeholder-container {
  opacity: 0 !important;
}
.ap-field.text-readonly .ap-field-wrapper {
  cursor: pointer;
}
.ap-field.text-readonly .ap-field-wrapper .ap-field-input {
  cursor: pointer;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ap-field-datepicker-dialog-container {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  pointer-events: none;
  z-index: 99;
}

.ap-field-cleaner.disabled {
  pointer-events: none;
}
.ap-field-cleaner.readonly {
  opacity: var(--opacity-6);
  pointer-events: none;
}

.ap-inputnumber-container .ap-field-suffix-wrapper {
  flex-direction: column;
}
.ap-inputnumber-container .icon-up-chevron-outline, .ap-inputnumber-container .icon-down-chevron-outline {
  width: 2.5rem;
  height: 1.25rem;
  cursor: pointer;
  transition: all 0.3s;
  color: var(--color-text-heading);
  border-radius: 2px;
}
.ap-inputnumber-container .icon-up-chevron-outline.disabled, .ap-inputnumber-container .icon-down-chevron-outline.disabled {
  cursor: not-allowed;
  color: rgba(var(--color-text-light-rgb), var(--opacity-5));
}
.ap-inputnumber-container .icon-up-chevron-outline.disabled:hover, .ap-inputnumber-container .icon-down-chevron-outline.disabled:hover {
  background-color: transparent;
}
.ap-inputnumber-container .icon-up-chevron-outline:hover, .ap-inputnumber-container .icon-down-chevron-outline:hover {
  background-color: var(--color-background-hover-selected);
}
.ap-inputnumber-container .icon-up-chevron-outline:before, .ap-inputnumber-container .icon-down-chevron-outline:before {
  margin-top: -3px;
}

.ap-field.error .ap-inputnumber-container .icon-up-chevron-outline:hover, .ap-field.error .ap-inputnumber-container .icon-down-chevron-outline:hover {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3)) !important;
}

.ap-field-email-validation-error {
  margin: 0.5rem 0 0.75rem 0.5rem;
  height: 0.75rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
  color: var(--color-text-error);
}

.ap-field-demo-wrapper {
  width: 20rem;
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.ap-field-email-validation-suggestion {
  margin: 0.5rem 0 0.75rem 0.5rem;
  height: 0.75rem;
  line-height: 0.75rem;
  font-size: 0.75rem;
  color: var(--color-text-body);
}
.ap-field-email-validation-suggestion span {
  color: var(--color-background-primary);
  font-weight: var(--font-weight-2);
  cursor: pointer;
}

.ap-field-password .ap-field-password-creator > span.highlight {
  color: var(--color-background-primary);
}

.ap-field-password .ap-field:not(.has-length) .ap-field-password-creator,
.ap-field-password .ap-field:not(:focus-within) .ap-field-password-creator {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-10px);
}
.ap-field-password .ap-field-password-creator {
  position: absolute;
  left: 0;
  top: var(--spacing-8);
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-4);
  margin: var(--spacing-3) 0;
  color: rgba(var(--color-text-light-rgb), var(--opacity-6));
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
}
.ap-field-password .ap-field-password-creator > span {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-1);
}
.ap-field-password .ap-field-password-creator > span .icon-circle-checkmark-fill {
  width: 12px;
  height: 12px;
  line-height: 12px;
  font-size: 8px;
}
.ap-field-password .ap-field-password-creator > span .icon-circle-checkmark-fill::before {
  font-weight: var(--font-weight-3);
}
.ap-field-password .ap-field-password-creator > span .ap-field-password-condition {
  line-height: 12px;
  font-size: 12px;
}
.ap-field-password .ap-field-password-creator > span.highlight {
  color: var(--color-background-primary);
}
.ap-field-password .ap-field-password-condition-sr-only {
  position: absolute;
  left: 0;
  top: var(--spacing-8);
  clip: rect(0 0 0 0);
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: no-wrap;
}

.ap-field-password .ap-field-password-condition-sr-only {
  position: absolute;
  left: 0;
  top: 3rem;
  clip: rect(0 0 0 0);
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ap-field-dropdown-icon {
  padding: var(--spacing-1);
  width: 24px;
  height: 24px;
  user-select: none;
}

.ap-field-dropdown-phone .ap-field-dropdown-item .ap-option-item .ap-checkbox-label .ap-option-prefix {
  display: flex;
  margin: 0;
}

.ap-field .ap-field-container:not(.show) .ap-field-dropdown-wrapper .ap-field-dropdown {
  visibility: hidden;
  opacity: 0;
  transform: translateY(var(--spacing-4));
}

.ap-filter {
  border-radius: 0.25rem;
}
.ap-filter ul,
.ap-filter li {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ap-filter .hide {
  display: none;
}
.ap-filter-button {
  transition: margin-bottom 0.3s;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 2rem;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--border-radius-2);
  -webkit-backdrop-filter: blur(var(--spacing-2));
  backdrop-filter: blur(var(--spacing-2));
  background-color: transparent;
  cursor: pointer;
}
.ap-filter-button:hover {
  background-color: var(--color-background-hover);
}
.ap-filter-button-title {
  text-align: start;
  letter-spacing: -0.025rem;
  height: 1.5rem;
  margin-right: var(--spacing-3);
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  color: var(--color-text-heading);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-filter-button .Appkit4-icon {
  color: var(--color-text-heading);
  transform: rotate(0);
  transition: transform 0.3s;
  flex-grow: 0;
  flex-shrink: 0;
}
.ap-filter-button .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-filter-button .Appkit4-icon.rotate {
  transform: rotate(-180deg);
}
.ap-filter .ap-filter-list {
  border-radius: var(--border-radius-2);
  -webkit-backdrop-filter: blur(var(--spacing-2));
  backdrop-filter: blur(var(--spacing-2));
  transition-property: height;
  transition-duration: 350ms;
  background-color: transparent;
  padding-top: var(--spacing-2);
}
.ap-filter .ap-filter-list .ap-filter-item:last-child {
  height: 2rem;
}
.ap-filter .ap-filter-list .ap-filter-item:not(:last-child) {
  height: 2.25rem;
}
.ap-filter .ap-filter-list .ap-filter-item-container {
  display: flex;
  justify-content: space-between;
  height: 2rem;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-2);
  border-radius: var(--border-radius-2);
}
.ap-filter .ap-filter-list .ap-filter-item-container:not(:last-child) {
  margin-bottom: var(--spacing-2);
}
.ap-filter .ap-filter-list .ap-filter-item-container .disabled {
  opacity: var(--opacity-5);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left {
  display: flex;
  overflow: hidden;
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .icon-radio-deselected-fill, .ap-filter .ap-filter-list .ap-filter-item-container-left .icon-checkbox-deselected-fill {
  color: var(--color-text-body) !important;
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .ap-checkbox-label {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .complete {
  background-color: var(--color-background-success);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .inprogress {
  background-color: var(--color-background-warning);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .error {
  background-color: var(--color-background-danger);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left .draft {
  background-color: var(--color-text-heading);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left-status {
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
  border-radius: 50%;
  width: var(--spacing-3);
  height: var(--spacing-3);
  margin: var(--spacing-3) var(--spacing-2) var(--spacing-3) var(--spacing-2);
}
.ap-filter .ap-filter-list .ap-filter-item-container-left-name {
  height: 1.5rem;
  margin-left: var(--spacing-2);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-filter .ap-filter-list .ap-filter-item-container-num {
  height: 1.5rem;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  text-align: right;
  color: var(--color-text-light);
  max-width: 46%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: none;
}
.ap-filter .ap-filter-list .ap-filter-item-container .hidden-text {
  width: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
}
.ap-filter .ap-filter-list .ap-filter-item-hover {
  cursor: pointer;
}
.ap-filter .ap-filter-list .ap-filter-item-hover:hover {
  background-color: var(--color-background-hover);
  -webkit-backdrop-filter: 4px;
  backdrop-filter: 4px;
}
.ap-filter .ap-filter-list .ap-filter-item-focus:focus {
  outline: auto 2px Highlight; /* Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Webkit, Safari */
}

.not-allowed {
  cursor: not-allowed;
}

.ap-footer {
  font-size: 0.875rem;
  color: var(--color-text-heading);
  line-height: 1.25rem;
  letter-spacing: -0.2px;
}
.ap-footer .ap-footer-link-group {
  display: flex;
  flex-wrap: wrap;
}
.ap-footer .ap-footer-link-group .ap-footer-link .ap-footer-divider {
  margin: 0 var(--spacing-4);
  height: 1.25rem;
}
.ap-footer .ap-footer-link-group .ap-footer-link:last-child .ap-footer-divider {
  display: none;
}

.ap-header {
  position: relative;
  height: 4rem;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-6);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  z-index: 99;
  background-color: rgba(var(--color-background-selected-rgb), var(--opacity-6));
  backdrop-filter: blur(1.25rem);
  -webkit-backdrop-filter: blur(1.25rem);
}
.ap-header.ap-header-responsive-default .ap-header-options-item.hamburger {
  display: none;
}
@media (max-width: 576px) {
  .ap-header.ap-header-responsive-default .ap-header-content.hamburger-hidden {
    display: none;
  }
  .ap-header.ap-header-responsive-default .ap-header-options.hamburger-hidden .ap-header-options-item:not(.hamburger) {
    display: none;
  }
  .ap-header.ap-header-responsive-default .ap-header-options-item {
    width: 2.5rem;
  }
  .ap-header.ap-header-responsive-default .ap-header-options-item.has-icon.has-label .ap-header-options-label {
    display: none;
  }
  .ap-header.ap-header-responsive-default .ap-header-options-item.has-icon.has-label.reverse {
    padding-left: var(--spacing-3);
  }
  .ap-header.ap-header-responsive-default .ap-header-options-item.has-icon.has-label:not(.reverse) {
    padding-right: var(--spacing-3);
  }
  .ap-header.ap-header-responsive-default .ap-header-options-item.hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ap-header.ap-header-responsive-default .hamburger-hidden-user {
    display: none;
  }
}
.ap-header.ap-header-responsive-manual .ap-header-options-item.hamburger {
  display: none;
}
.ap-header.ap-header-responsive-manual .ap-header-content.hamburger-hidden {
  display: none;
}
.ap-header.ap-header-responsive-manual .ap-header-options.hamburger-hidden .ap-header-options-item:not(.hamburger) {
  display: none;
}
.ap-header.ap-header-responsive-manual .ap-header-options-item {
  width: 2.5rem;
}
.ap-header.ap-header-responsive-manual .ap-header-options-item.has-icon.has-label .ap-header-options-label {
  display: none;
}
.ap-header.ap-header-responsive-manual .ap-header-options-item.has-icon.has-label.reverse {
  padding-left: var(--spacing-3);
}
.ap-header.ap-header-responsive-manual .ap-header-options-item.has-icon.has-label:not(.reverse) {
  padding-right: var(--spacing-3);
}
.ap-header.ap-header-responsive-manual .ap-header-options-item.hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ap-header.ap-header-responsive-manual .hamburger-hidden-user {
  display: none;
}
.ap-header.solid {
  background-color: var(--color-background-container);
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  box-shadow: 0 0 0.0625rem 0 var(--color-background-default);
}
.ap-header .ap-header-heading {
  display: flex;
  align-items: center;
  height: 2.5rem;
}
.ap-header .ap-header-heading .ap-header-logo {
  margin-right: var(--spacing-2);
  width: 2.625rem;
  height: 2.5rem;
  mask-image: url("../images/PwC_logo_simplified.svg");
  -webkit-mask-image: url("../images/PwC_logo_simplified.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  background-color: var(--color-text-heading);
  cursor: pointer;
}
.ap-header .ap-header-heading .ap-header-title {
  font-size: 1rem;
  line-height: 1.25rem;
  white-space: nowrap;
  color: var(--color-text-heading);
}
.ap-header .ap-header-heading .ap-header-subtitle {
  color: var(--color-text-heading);
  display: flex;
  align-items: center;
}
.ap-header .ap-header-heading .ap-header-subtitle .ap-header-subtitle-divider {
  width: 0.0625rem;
  height: 1.5rem;
  margin: 0 var(--spacing-4);
  opacity: var(--opacity-5);
  background-color: var(--color-text-heading);
}
.ap-header .ap-header-heading .ap-header-options-item.hamburger {
  margin-right: var(--spacing-4);
}
.ap-header .ap-header-content {
  position: absolute;
  justify-self: center;
  display: flex;
  align-items: center;
}
.ap-header .ap-header-options {
  display: flex;
  column-gap: var(--spacing-4);
  align-items: center;
}
.ap-header .ap-header-options-item {
  position: relative;
  display: flex;
  column-gap: var(--spacing-2);
  align-items: center;
  height: 2.5rem;
  color: var(--color-text-heading);
  border-radius: var(--border-radius-2);
  cursor: pointer;
}
.ap-header .ap-header-options-item:hover {
  background-color: rgba(var(--color-background-hover-selected-rgb), var(--opacity-6));
  backdrop-filter: blur(1.25rem);
  -webkit-backdrop-filter: blur(1.25rem);
}
.ap-header .ap-header-options-item.reverse {
  flex-direction: row-reverse;
  padding: var(--spacing-3) var(--spacing-3) var(--spacing-3) var(--spacing-4);
}
.ap-header .ap-header-options-item.has-icon.has-label:not(.reverse) {
  padding: var(--spacing-3) var(--spacing-4) var(--spacing-3) var(--spacing-3);
}
.ap-header .ap-header-options-item.has-icon:not(.has-label) {
  padding: var(--spacing-3);
}
.ap-header .ap-header-options-item.has-label:not(.has-icon) {
  padding: var(--spacing-3) var(--spacing-4);
}
.ap-header .ap-header-options-item .ap-header-options-icon-wrapper {
  width: 1.5rem;
  height: 1.5rem;
}
.ap-header .ap-header-options-item .ap-header-options-label {
  line-height: 1.5rem;
}

.ap-header .ap-header-options-item.hamburger-button-icon {
  /* Menu toggle styles. */
  /* Animate menu icon (lines). */
}
.ap-header .ap-header-options-item.hamburger-button-icon .icon-menu-toggle {
  font-size: 0.875em;
}
.ap-header .ap-header-options-item.hamburger-button-icon .icon-menu-toggle:focus {
  outline: thin dotted;
  outline-offset: -2px;
}
.ap-header .ap-header-options-item.hamburger-button-icon .icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  position: relative; /* Align more nicely with capital letters */
  top: -0.0625em;
}
.ap-header .ap-header-options-item.hamburger-button-icon .icon-menu-toggle {
  width: 1.25rem;
  height: 1rem;
  top: 0;
  border: none;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
}
.ap-header .ap-header-options-item.hamburger-button-icon .svg-menu-toggle .line {
  opacity: 1;
  transform: rotate(0) translateY(0) translateX(0);
  transform-origin: 0.875rem 0.875rem;
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s ease-in-out;
}
.ap-header .ap-header-options-item.hamburger-button-icon .svg-menu-toggle .line-1 {
  transform-origin: 0.875rem 0.875rem;
}
.ap-header .ap-header-options-item.hamburger-button-icon .svg-menu-toggle .line-3 {
  transform-origin: 0.875rem 0.875rem;
}
.ap-header .ap-header-options-item.hamburger-button-icon.opened .icon-menu-toggle .svg-menu-toggle .line-1 {
  transform: rotate(45deg) translateY(0.2em) translateX(-0.3em);
}
.ap-header .ap-header-options-item.hamburger-button-icon.opened .icon-menu-toggle .svg-menu-toggle .line-2 {
  opacity: 0;
}
.ap-header .ap-header-options-item.hamburger-button-icon.opened .icon-menu-toggle .svg-menu-toggle .line-3 {
  transform: rotate(-45deg) translateY(0.2em) translateX(0.6em);
}

* {
  margin: 0;
}

.ap-list {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: var(--spacing-3);
  color: var(--color-text-body);
  font-size: 1rem;
  line-height: 1rem;
  list-style: none;
  font-stretch: normal;
  background-color: var(--color-background-container);
}
.ap-list-pagination {
  margin-top: 0.625rem;
  text-align: right;
}
.ap-list-pagination .a-pagination-options {
  text-align: left;
}
.ap-list-more {
  margin-top: 0.625rem;
  text-align: center;
}
.ap-list-more button {
  padding-right: 2rem;
  padding-left: 2rem;
}
.ap-list-spin {
  min-height: 2.5rem;
  text-align: center;
}
.ap-list-empty-text {
  padding: 10px;
  color: var(--color-text-body);
  font-size: 1rem;
  text-align: center;
}
.ap-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ap-list .ap-dragging {
  cursor: move;
  background-color: var(--color-background-hover);
  box-shadow: var(--box-shadow-3);
  border-radius: var(--border-radius-3);
}
.ap-list .ap-list-item-draggable {
  cursor: move;
  border-radius: var(--border-radius-3);
  padding: 0;
}
.ap-list .ap-list-item-draggable:not(:last-child) {
  margin-bottom: var(--spacing-2);
}
.ap-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-4);
  color: var(--color-text-body);
  margin-top: var(--spacing-2);
  cursor: pointer;
}
.ap-list-item span.secondary-text {
  color: var(--color-text-light);
  word-break: break-all;
}
.ap-list-item-no-focus:focus {
  outline: none;
}
.ap-list-item.selected {
  background-color: var(--color-background-selected);
  border-radius: var(--border-radius-2);
  box-shadow: none;
}
.ap-list-item:first-child {
  margin-top: 0;
}
.ap-list-item span {
  line-height: 1.5rem;
  word-break: break-all;
}
.ap-list-item-extra {
  font-size: 1rem;
  line-height: 0;
  letter-spacing: -0.025rem;
  color: var(--color-text-light);
}
.ap-list-item-meta {
  display: flex;
  flex: 1;
  align-items: center;
  max-width: 100%;
}
.ap-list-item-meta-avatar {
  margin-right: var(--spacing-3);
}
.ap-list-item-meta-avatar span {
  word-break: normal;
}
.ap-list-item-meta-content {
  flex: 1 0;
  width: 0;
  align-items: center;
  color: var(--color-text-body);
}
.ap-list-item-meta-title {
  color: var(--color-text-body);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
}
.ap-list-item-meta-title > a {
  color: var(--color-text-body);
  transition: all 0.3s;
}
.ap-list-item-meta-title > a:hover {
  color: var(--color-text-body);
}
.ap-list-item-meta-description {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-light);
}
.ap-list-item-action {
  flex: 0 0 auto;
  margin-left: var(--spacing-8);
  padding: 0;
  font-size: 0;
  list-style: none;
  max-width: 50%;
}
.ap-list-item-action > li {
  position: relative;
  font-size: 1rem;
  line-height: 0;
  letter-spacing: -0.025rem;
  text-align: center;
}
.ap-list-item-action > li:first-child {
  padding-left: 0;
  margin-top: 0;
}
.ap-list-item-action > li span {
  color: var(--color-text-light);
  word-break: break-all;
}
.ap-list-item-action > li .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.0625rem;
  height: 0.875rem;
  margin-top: -7px;
  background-color: var(--color-background-secondary);
}
.ap-list-item:hover {
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
}
.ap-list-header {
  background: var(--color-background-secondary);
}
.ap-list-footer {
  background: var(--color-background-secondary);
}
.ap-list-header, .ap-list-footer {
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-5);
}
.ap-list-empty {
  padding: var(--spacing-5) 0;
  color: var(--color-background-hover);
  font-size: 0.75rem;
  text-align: center;
}
.ap-list-split .ap-list-item:last-child {
  border-bottom: none;
}
.ap-list-split .ap-list-header {
  border-bottom: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}
.ap-list-split .ap-list-empty .ap-list-footer {
  border-top: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}
.ap-list-loading .ap-list-spin-nested-loading {
  min-height: 2rem;
}
.ap-list-split .ap-list-something-after-last-item .a-spin-container > .ap-list-items > .ap-list-item:last-child {
  border-bottom: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}
.ap-list-lg .ap-list-item {
  padding: var(--spacing-5);
}
.ap-list-sm .ap-list-item {
  padding: var(--spacing-3);
}
.ap-list-vertical .ap-list-item {
  align-items: initial;
}
.ap-list-vertical .ap-list-item-main {
  display: block;
  flex: 1;
}
.ap-list-vertical .ap-list-item-extra {
  margin-left: 2.5rem;
}
.ap-list-vertical .ap-list-item-meta {
  margin-bottom: var(--spacing-5);
}
.ap-list-vertical .ap-list-item-meta-title {
  margin-bottom: var(--spacing-5);
  color: var(--color-text-body);
  font-size: 1rem;
}
.ap-list-vertical .ap-list-item-action {
  margin-top: var(--spacing-5);
  margin-left: auto;
}
.ap-list-vertical .ap-list-item-action > li {
  padding: 0 var(--spacing-5);
}
.ap-list-vertical .ap-list-item-action > li:first-child {
  padding-left: 0;
}
.ap-list-grid .a-col > .ap-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: var(--spacing-5);
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ap-list-item-no-flex {
  display: block;
}
.ap-list:not(.ap-list-vertical) .ap-list-item-no-flex .ap-list-item-action {
  float: right;
}

.ap-list-bordered {
  box-shadow: var(--level-1);
  border-radius: var(--border-radius-3);
}
.ap-list-bordered .ap-list-header {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
}
.ap-list-bordered .ap-list-footer {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
}
.ap-list-bordered .ap-list-item {
  padding-right: var(--spacing-4);
  padding-left: var(--spacing-4);
}
.ap-list-bordered .ap-list-pagination {
  margin: 0.625rem 0.625rem;
}
.ap-list-bordered.ap-list-sm .ap-list-item {
  padding: var(--spacing-3);
}
.ap-list-bordered.ap-list-sm .ap-list-header,
.ap-list-bordered.ap-list-sm .ap-list-footer {
  padding: 0.625rem;
}
.ap-list-bordered.ap-list-lg .ap-list-item {
  padding: 0.625rem;
}
.ap-list-bordered.ap-list-lg .ap-list-header,
.ap-list-bordered.ap-list-lg .ap-list-footer {
  padding: 0.625rem;
}
.ap-list-bordered .ap-divider {
  width: 100%;
  height: 0.0625rem;
  margin-top: 0.25rem;
  background-color: var(--color-background-hover-selected);
}

.ap-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  --linearLoadingWidth: 148px;
  --containerWidth: 148px;
  --circularLoadingWidth: 24px;
  --rotateDegree: 135deg;
  --circularLoadingCompactWidth: 16px;
}
.ap-loading .ap-loading-linear {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0.25rem;
  border-radius: var(--border-radius-1);
  overflow: hidden;
  vertical-align: middle;
}
.ap-loading .ap-loading-linear .linear-bar-inner {
  width: 100%;
  height: 0.25rem;
  opacity: var(--opacity-4);
  background-color: var(--color-background-primary);
}
.ap-loading .ap-loading-linear .linear-bar-bg {
  position: absolute;
  display: block;
  width: var(--linearLoadingWidth);
  height: 0.25rem;
  top: 0;
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-primary);
  animation: progress 2s linear;
  transition: width 0.3s linear;
}
.ap-loading .ap-loading-linear.bounces .linear-bar-bg {
  width: var(--linearLoadingWidth);
  height: 0.25rem;
  background-color: var(--color-background-primary);
  animation: bounceReact 2s linear infinite;
}
.ap-loading .ap-loading-linear.compact {
  height: 2px;
  border-radius: 0.0625rem;
}
.ap-loading .ap-loading-linear.compact .linear-bar-bg {
  border-radius: 0.0625rem;
}
.ap-loading .ap-loading-linear.compact .linear-bar-inner {
  height: 2px;
}
.ap-loading .ap-loading-linear.compact .linear-bar-bg {
  height: 2px;
}
.ap-loading .ap-loading-circular {
  width: var(--circularLoadingWidth);
  height: var(--circularLoadingWidth);
  border-radius: 50%;
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-4));
}
.ap-loading .ap-loading-circular.animation.loading {
  animation: loading 1s infinite linear;
}
.ap-loading .ap-loading-circular .mask, .ap-loading .ap-loading-circular .circle .progress-bar {
  width: var(--circularLoadingWidth);
  height: var(--circularLoadingWidth);
  position: absolute;
  border-radius: 50%;
}
.ap-loading .ap-loading-circular .mask .progress-bar {
  clip: rect(0px, calc(var(--circularLoadingWidth) / 2), var(--circularLoadingWidth), 0px);
  background-color: var(--color-background-primary);
}
.ap-loading .ap-loading-circular .circle .mask {
  clip: rect(0px, var(--circularLoadingWidth), var(--circularLoadingWidth), calc(var(--circularLoadingWidth) / 2));
}
.ap-loading .ap-loading-circular .mask.ap-circular-full,
.ap-loading .ap-loading-circular .circle .progress-bar {
  animation: fill ease-in-out 1s;
  transform: rotate(var--(rotateDegree));
}
.ap-loading .ap-loading-circular .inside-circle {
  width: calc(var(--circularLoadingWidth) - 6px);
  height: calc(var(--circularLoadingWidth) - 6px);
  border-radius: 50%;
  background: var(--color-background-alt);
  text-align: center;
  margin-top: 0.1875rem;
  margin-left: 0.1875rem;
  position: absolute;
  z-index: 99;
}
.ap-loading .ap-loading-circular.compact {
  width: var(--circularLoadingCompactWidth);
  height: var(--circularLoadingCompactWidth);
  border-radius: 50%;
  background-color: rgba(var(--color-background-primary-rgb), var(--opacity-4));
}
.ap-loading .ap-loading-circular.compact.animation {
  animation: loading 1s infinite linear;
}
.ap-loading .ap-loading-circular.compact .mask, .ap-loading .ap-loading-circular.compact .circle .progress-bar {
  width: var(--circularLoadingCompactWidth);
  height: var(--circularLoadingCompactWidth);
  position: absolute;
  border-radius: 50%;
}
.ap-loading .ap-loading-circular.compact .mask .progress-bar {
  clip: rect(0px, calc(var(--circularLoadingCompactWidth) / 2), var(--circularLoadingCompactWidth), 0px);
  background-color: var(--color-background-primary);
}
.ap-loading .ap-loading-circular.compact .circle .mask {
  clip: rect(0px, var(--circularLoadingCompactWidth), var(--circularLoadingCompactWidth), calc(var(--circularLoadingCompactWidth) / 2));
}
.ap-loading .ap-loading-circular.compact .mask.ap-circular-full,
.ap-loading .ap-loading-circular.compact .circle .progress-bar {
  animation: fill ease-in-out 1s;
  transform: rotate(var--(rotateDegree));
}
.ap-loading .ap-loading-circular.compact .inside-circle {
  width: calc(var(--circularLoadingCompactWidth) - 4px);
  height: calc(var(--circularLoadingCompactWidth) - 4px);
  border-radius: 50%;
  background: var(--color-background-alt);
  text-align: center;
  margin-top: var(--spacing-1);
  margin-left: var(--spacing-1);
  position: absolute;
  z-index: 99;
}

@keyframes progress {
  0% {
    width: 0px;
  }
  30% {
    width: 0.3125rem;
  }
  100% {
    width: var(--linearLoadingWidth);
  }
}
@keyframes bounceReact {
  from, to {
    transform: translateX(0px) scale(1, 1);
    animation-timing-function: ease-in;
  }
  45% {
    transform: translateX(calc(var(--containerWidth) - var(--linearLoadingWidth))) scale(1, 1);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(calc(var(--containerWidth) - var(--linearLoadingWidth))) scale(1, 1);
    animation-timing-function: linear;
  }
  85% {
    transform: translateX(0px) scale(1, 1);
    animation-timing-function: linear;
  }
  100% {
    transform: translateX(0px) scale(1, 1);
    animation-timing-function: ease-out;
  }
}
@keyframes fill {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--rotateDegree));
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.ap-modal-live-static {
  position: relative;
  background-color: var(--color-background-container);
  box-shadow: var(--box-shadow-3);
  border-radius: var(--border-radius-3);
  font-size: 1rem;
  width: 33.75rem;
  margin: 0 auto;
}

.ap-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-6);
}
.ap-modal-header .ap-badge {
  margin-right: var(--spacing-3);
}
.ap-modal-header .ap-modal-title {
  font-size: var(--spacing-6);
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
  margin-right: auto;
}

.ap-modal-body {
  padding: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
  max-height: 500px;
}
.ap-modal-body.ap-modal-body-only {
  padding-bottom: var(--spacing-6);
}
.ap-modal-body p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
}

.ap-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--spacing-3) var(--spacing-6) var(--spacing-6) var(--spacing-6);
  margin-top: calc(-1 * var(--spacing-3));
  overflow: auto;
  min-height: 4rem;
}
.ap-modal-footer-customize {
  display: flex;
}

.ap-modal-header-icons-container {
  position: absolute;
  top: var(--spacing-4);
  right: var(--spacing-4);
  display: flex;
  gap: var(--spacing-2);
}
.ap-modal-header-icons-container .ap-modal-header-icons {
  display: flex;
  align-items: center;
}
.ap-modal-header-icons-container .ap-modal-header-icon {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  background-color: transparent;
}
.ap-modal-header-icons-container .ap-modal-header-icon:hover {
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
  cursor: pointer;
}
.ap-modal-header-icons-container .ap-modal-header-icon.ap-modal-header-more {
  margin-right: var(--spacing-2);
}
.ap-modal-header-icons-container .ap-modal-header-icons {
  display: flex;
  align-items: center;
}
.ap-modal-header-icons-container .ap-modal-header-close-wrapper {
  display: flex;
  align-items: center;
  padding: var(--spacing-2);
  margin: calc(-1 * var(--spacing-2));
  overflow: hidden;
}
.ap-modal-header-icons-container .ap-modal-header-icon {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  background-color: transparent;
}
.ap-modal-header-icons-container .ap-modal-header-icon:hover {
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
  cursor: pointer;
}
.ap-modal-header-icons-container .ap-modal-header-icon.ap-modal-header-more {
  margin-right: var(--spacing-2);
}
.ap-modal-header-icons-container .ap-modal-header-icon .Appkit4-icon {
  color: var(--color-text-heading);
}

.ap-modal-wrapper {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
  z-index: 2000;
  backdrop-filter: blur(2px);
  background-color: rgba(var(--color-background-default-inverse-rgb), var(--opacity-5));
  overflow: auto;
  opacity: 0;
  transition: all 0.3s;
}
.ap-modal-wrapper.show {
  opacity: 1;
}

.ap-modal {
  position: relative;
  background-color: var(--color-background-container);
  box-shadow: var(--box-shadow-3);
  border-radius: var(--border-radius-3);
  font-size: 1rem;
  transition: margin 0.3s;
  max-height: 100vh;
  max-width: 100%;
  overflow: auto;
  margin-top: 10px;
}

.ap-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  padding: var(--spacing-6);
  min-height: 4rem;
}
.ap-modal-header .ap-badge {
  margin-right: var(--spacing-3);
}
.ap-modal-header .ap-modal-title {
  font-size: var(--spacing-6);
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
  margin-right: auto;
}

.ap-modal-body {
  padding: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
  overflow: auto;
}
.ap-modal-body p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
}

.ap-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
  overflow: auto;
}
.ap-modal-footer .ap-button-secondary {
  margin-right: var(--spacing-3);
}
.ap-modal-footer-customize {
  display: flex;
}

.ap-modal-wrapper.show .ap-modal {
  margin-top: 0;
}

.ap-body-overflow-hidden {
  overflow: hidden;
}

@keyframes notificationMoveInCenter {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}
@keyframes notificationGlobalMoveIn {
  0% {
    transform: translateY(-100%);
    position: absolute;
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: 0;
    position: absolute;
  }
}
@keyframes notificationGlobalMoveOut {
  0% {
    transform: translateY(0%);
    position: absolute;
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(-100%);
    position: absolute;
  }
}
@keyframes notificationMoveIn {
  0% {
    transform: translateY(100%);
    position: absolute;
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes notificationMoveInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes notificationMoveInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.ap-notification-fade-entered {
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.99, 0.44, 0.44, 1.35);
}

@keyframes notificationMoveInLeftBottom {
  0% {
    transform: translateY(100%);
    opacity: 1;
    position: absolute;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade {
  width: 100%;
}

.ap-notifications-topHeader {
  left: 0;
  top: 0;
  width: 100%;
}
.ap-notifications-topHeader .ap-notification-fade-entered {
  animation-name: notificationGlobalMoveIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
}
.ap-notifications-topHeader .ap-notification-fade-exiting {
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-name: notificationGlobalMoveOut;
}

.ap-notifications-topLeft {
  align-items: flex-start;
  top: var(--spacing-6);
  left: 0;
}
.ap-notifications-topLeft .ap-notification-fade-entered {
  margin-left: auto;
  animation-name: notificationMoveInLeft;
}
.ap-notifications-topLeft .ap-notification-fade-exiting {
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-name: notificationMoveOut;
}

.ap-notifications-topRight {
  align-items: flex-end;
  top: var(--spacing-6);
  right: 0;
}
.ap-notifications-topRight .ap-notification-fade-entered {
  margin-left: auto;
  animation-name: notificationMoveInRight;
}
.ap-notifications-topRight .ap-notification-fade-exiting {
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-name: notificationMoveOut;
}

.ap-notifications-topCenter {
  top: var(--spacing-6);
  left: 50%;
  transform: translateX(-50%);
}
.ap-notifications-topCenter .ap-notification-fade-entered {
  animation-name: notificationMoveInCenter;
}
.ap-notifications-topCenter .ap-notification-fade-exiting {
  animation-duration: 0.4s;
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-name: notificationMoveOut;
}

.ap-notifications-center {
  top: 50%;
  left: 50%;
  transform: translateX(-50%, -50%);
}
.ap-notifications-center .ap-notification-fade-entered {
  animation-name: notificationMoveInCenter;
}
.ap-notifications-center .ap-notification-fade-exiting {
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.64, 0.65, 0.57, 1.13);
  animation-name: notificationMoveOut;
}

@keyframes notificationMoveOutBottom {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}
@keyframes notificationMoveOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
    max-height: 100px;
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0.8);
    max-height: 0;
    overflow: hidden;
  }
}
.ap-notifications {
  position: fixed;
  z-index: 1000;
}
.ap-notifications.ap-notifications-static {
  position: relative;
}

.ap-notifications-bottomLeft .ap-notification-fade-entered,
.ap-notifications-bottomCenter .ap-notification-fade-entered,
.ap-notifications-bottomRight .ap-notification-fade-entered {
  animation-name: notificationMoveIn;
}
.ap-notifications-bottomLeft .ap-notification-fade-exiting,
.ap-notifications-bottomCenter .ap-notification-fade-exiting,
.ap-notifications-bottomRight .ap-notification-fade-exiting {
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.7, -0.8, 0.4, 1.4);
}
.ap-notifications-bottomLeft .ap-notification-fade-exiting,
.ap-notifications-bottomCenter .ap-notification-fade-exiting,
.ap-notifications-bottomRight .ap-notification-fade-exiting {
  animation-name: notificationMoveOutBottom;
}

@keyframes growDown {
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(15%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    position: absolute;
    transform: translate3d(0, 0rem, 0);
  }
  60% {
    opacity: 1;
    position: absolute;
    transform: translate3d(0, 1.5625rem, 0);
  }
  90% {
    position: absolute;
    transform: translate3d(0, 0.3125rem, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
    position: absolute;
  }
}
.ap-notifications-bottomLeft {
  bottom: var(--spacing-6);
  left: 0;
}

.ap-notifications-bottomRight {
  bottom: var(--spacing-6);
  right: 0;
}

.ap-notifications-topCenter {
  top: var(--spacing-6);
}

.ap-notifications-bottomCenter {
  bottom: var(--spacing-6);
  left: 50%;
  transform: translateX(-50%);
}

.ap-notifications-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}

.ap-notifications-topHeader {
  top: 0;
  left: 0;
  width: 100%;
}

_::-webkit-full-page-media,
_:future,
.ap-notification .a-circle {
  stroke-width: 12px !important;
  stroke-dasharray: 38px !important;
}

.ap-notification {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 22.5rem;
  max-width: calc(100vw - 48px);
  max-height: 45rem;
  margin: 0.625rem;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-5);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  overflow-y: hidden;
}
.ap-notification::-webkit-scrollbar {
  width: 0 !important;
}
.ap-notification.ap-notice {
  background-color: var(--color-background-container-alt);
  box-shadow: none;
}
.ap-notification.ap-notice .ap-notification-icon-close:hover {
  background-color: var(--color-background-hover-selected);
}
.ap-notification.has-icon {
  padding: var(--spacing-4);
}
.ap-notification-warning .ap-notification-hyperlink {
  color: var(--color-background-tertiary);
}
.ap-notification .ap-notification-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: var(--color-text-heading);
  letter-spacing: -0.025rem;
  margin-right: 2.25rem;
  width: calc(100% - 32px);
  word-wrap: break-word;
}
.ap-notification .ap-notification-message {
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
}
.ap-notification .ap-notification-description {
  line-height: 1.5rem;
  color: var(--color-text-body);
  outline: none;
}
.ap-notification .ap-notification-hyperlink {
  color: var(--color-text-primary);
  line-height: 1.5rem;
  cursor: pointer;
}
.ap-notification .height {
  min-height: var(--spacing-7);
}

.ap-notifications-topHeader .ap-notification,
.ap-notifications-static-topHeader .ap-notification {
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 3rem;
  max-width: 100%;
  max-height: 3rem;
  margin: 0rem;
  border-radius: 0;
  box-shadow: none;
  padding: var(--spacing-4) var(--spacing-2);
  background-color: #252525;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-right: 0rem;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: #FFFFFF;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-message,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-message {
  font-weight: normal;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-description,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-description {
  color: #FFFFFF;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-toggle,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-toggle {
  margin-left: var(--spacing-1);
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon {
  transform: rotate(0);
  transition: transform 0.3s;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon:hover,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon:hover {
  cursor: pointer;
}
.ap-notifications-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon.roate,
.ap-notifications-static-topHeader .ap-notification .ap-notification-content .ap-notification-toggle .Appkit4-icon.roate {
  transform: rotate(-180deg);
}
.ap-notifications-topHeader .ap-notification .ap-notification-icon-close:hover,
.ap-notifications-static-topHeader .ap-notification .ap-notification-icon-close:hover {
  background-color: transparent;
}
.ap-notifications-topHeader .ap-notification .ap-notification-icon-close:hover::after,
.ap-notifications-static-topHeader .ap-notification .ap-notification-icon-close:hover::after {
  background-color: rgba(0, 0, 0, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-notifications-topHeader .ap-notification .height,
.ap-notifications-static-topHeader .ap-notification .height {
  color: #FFFFFF;
}
.ap-notifications-topHeader .ap-notification.success,
.ap-notifications-static-topHeader .ap-notification.success {
  background-color: var(--color-background-success);
}
.ap-notifications-topHeader .ap-notification.warning,
.ap-notifications-static-topHeader .ap-notification.warning {
  background-color: var(--color-background-warning);
}
.ap-notifications-topHeader .ap-notification.warning .ap-notification-content,
.ap-notifications-static-topHeader .ap-notification.warning .ap-notification-content {
  color: #474747;
}
.ap-notifications-topHeader .ap-notification.warning .ap-notification-content .ap-notification-description,
.ap-notifications-static-topHeader .ap-notification.warning .ap-notification-content .ap-notification-description {
  color: #474747;
}
.ap-notifications-topHeader .ap-notification.warning .height,
.ap-notifications-static-topHeader .ap-notification.warning .height {
  font-weight: normal;
  color: #474747;
}
.ap-notifications-topHeader .ap-notification.warning .a-countdown-wrapper .a-circle,
.ap-notifications-static-topHeader .ap-notification.warning .a-countdown-wrapper .a-circle {
  stroke: #474747;
}
.ap-notifications-topHeader .ap-notification.error,
.ap-notifications-static-topHeader .ap-notification.error {
  background-color: var(--color-background-danger);
}
.ap-notifications-topHeader .ap-notification .a-countdown-wrapper svg,
.ap-notifications-static-topHeader .ap-notification .a-countdown-wrapper svg {
  transform: rotate(-90deg);
}
.ap-notifications-topHeader .ap-notification .a-countdown-wrapper.a-circle-hide,
.ap-notifications-static-topHeader .ap-notification .a-countdown-wrapper.a-circle-hide {
  opacity: 0;
  overflow: hidden;
}
.ap-notifications-topHeader .ap-notification .a-countdown-wrapper.a-circle-show,
.ap-notifications-static-topHeader .ap-notification .a-countdown-wrapper.a-circle-show {
  opacity: 1;
  z-index: 999;
}
.ap-notifications-topHeader .ap-notification .a-countdown-wrapper .a-circle,
.ap-notifications-static-topHeader .ap-notification .a-countdown-wrapper .a-circle {
  fill: transparent;
  stroke: var(--color-background-secondary);
  stroke-width: 0.75rem;
  stroke-dasharray: 2.375rem;
  stroke-dashoffset: 2.375rem;
}

.ap-notifications-static-topHeader {
  position: relative;
  z-index: 10;
}
.ap-notifications-static-topHeader .ap-notification {
  transition-timing-function: ease-in-out;
}

.ap-notification-icon {
  margin-right: var(--spacing-2);
}
.ap-notification-icon.icon-warning-fill {
  color: var(--color-text-warning);
}
.ap-notification-icon.icon-success-fill {
  color: var(--color-text-success);
}
.ap-notification-icon.icon-error-fill {
  color: var(--color-text-error);
}
.ap-notification-icon.icon-error-fill::before {
  font-size: 1rem;
}

.ap-notification-icon-close {
  position: absolute;
  top: var(--spacing-2);
  right: var(--spacing-2);
  padding: var(--spacing-3);
  background: transparent;
  color: var(--color-text-heading);
}
.ap-notification-icon-close:focus.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 2px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: -10px;
}
.ap-notification-icon-close:hover {
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: var(--color-background-hover);
}

.Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}

.ap-notification-content-dialog {
  position: absolute;
  width: 100%;
  z-index: 99;
  opacity: 0;
  max-height: 0rem;
  overflow: hidden;
  transition: all 0.3s linear;
  visibility: hidden;
}
.ap-notification-content-dialog.open {
  opacity: var(--opacity-7);
  visibility: visible;
  transform-origin: top center;
  max-height: 22.4375rem;
  border: solid 0.0625rem var(--color-background-border);
  background-color: #FFFFFF;
  overflow: visible;
  animation: bounceInDown 0.5s ease-in backwards;
}
.ap-notification-content-dialog .ap-padding-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-6) 0;
}
.ap-notification-content-dialog .ap-notification-image-box {
  width: 17.75rem;
  height: 11.9375rem;
  border-radius: 0.25rem;
  background-color: #F3F3F3;
}
.ap-notification-content-dialog .ap-notification-text-box {
  margin: var(--spacing-6) 0;
  width: 26.0625rem;
  height: 3rem;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: #696969;
}
.ap-notification-content-dialog .ap-notification-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.ap-notification-content-dialog .ap-notification-button .ap-button-secondary.leftbtn {
  margin-right: var(--spacing-3);
}

.search-result {
  border: 0;
  clip: rect(0px, 0px, 0px, 0px);
  height: 0.0625rem;
  margin-bottom: -0.0625rem;
  margin-right: -0.0625rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0.0625rem;
}

.ap-pagination {
  display: flex;
  grid-auto-flow: column;
  padding: var(--spacing-2);
  height: 3.5rem;
  border-radius: var(--border-radius-3);
}
.ap-pagination .ap-pagination-btn-prev,
.ap-pagination .ap-pagination-btn-next {
  margin: var(--spacing-2);
  line-height: 2.5rem;
  border-radius: var(--border-radius-2);
  text-align: center;
  color: var(--color-text-body);
  cursor: pointer;
  user-select: none;
}
.ap-pagination .ap-pagination-btn-prev .Appkit4-icon,
.ap-pagination .ap-pagination-btn-next .Appkit4-icon {
  font-weight: var(--font-weight-2);
}
.ap-pagination .ap-pagination-btn-prev .Appkit4-icon::before,
.ap-pagination .ap-pagination-btn-next .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-pagination .ap-pagination-btn-prev .height,
.ap-pagination .ap-pagination-btn-next .height {
  height: 1.5rem;
}
.ap-pagination .ap-pagination-btn-prev::before,
.ap-pagination .ap-pagination-btn-next::before {
  width: 2.125rem;
  height: 2.125rem;
  line-height: 2.125rem;
  font-size: 1rem;
}
.ap-pagination .ap-pagination-btn-prev:not(.disabled):hover,
.ap-pagination .ap-pagination-btn-next:not(.disabled):hover {
  background-color: var(--color-background-hover);
}
.ap-pagination .ap-pagination-btn-prev.disabled,
.ap-pagination .ap-pagination-btn-next.disabled {
  cursor: not-allowed;
  color: var(--color-text-disabled);
}
.ap-pagination .ap-pagination-btn-prev:not(.large) {
  width: 2.5rem;
}
.ap-pagination .ap-pagination-btn-next:not(.large) {
  width: 2.5rem;
}
.ap-pagination .ap-pagination-btn-prev.large {
  padding: 0 var(--spacing-7) 0 var(--spacing-4);
}
.ap-pagination .ap-pagination-btn-prev.large .ap-pagination-btn-text {
  margin-left: var(--spacing-2);
  line-height: 2rem;
  height: 1.5rem;
  display: inline-block;
}
.ap-pagination .ap-pagination-btn-next.large {
  padding: 0 var(--spacing-4) 0 var(--spacing-7);
  height: 2.5rem;
  text-align: left;
}
.ap-pagination .ap-pagination-btn-next.large .ap-pagination-btn-text {
  margin-right: var(--spacing-2);
  line-height: 2rem;
  height: 1.5rem;
  display: inline-block;
}
.ap-pagination .ap-pagination-field {
  display: flex;
  column-gap: var(--spacing-3);
  align-items: center;
  padding: var(--spacing-2);
  margin: var(--spacing-2) 0 var(--spacing-2) 0;
  min-width: 6.75rem;
}
.ap-pagination .ap-pagination-field .ap-field {
  width: 3rem;
}
.ap-pagination .ap-pagination-field .ap-field .ap-field-wrapper {
  height: 2rem;
}
.ap-pagination .ap-pagination-field .ap-field .ap-field-wrapper input {
  margin: var(--spacing-2) var(--spacing-3);
  text-align: center;
}
.ap-pagination .ap-pagination-field .ap-pagination-total {
  display: flex;
  column-gap: var(--spacing-2);
  color: var(--color-text-body);
}
.ap-pagination .ap-pagination-field span {
  pointer-events: none;
  user-select: none;
  line-height: 1.5rem;
}

.ap-panel {
  position: relative;
  background: var(--color-background-container);
  border-radius: var(--border-radius-3);
}
.ap-panel-bordered {
  box-shadow: var(--box-shadow-1);
}
.ap-panel-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ap-panel-hoverable:hover {
  box-shadow: var(--box-shadow-2);
}
.ap-panel-head {
  min-height: 1rem;
  padding: var(--spacing-6) var(--spacing-6) 0 var(--spacing-6);
  margin-bottom: var(--spacing-4);
  color: var(--color-background-tertiary);
  font-weight: var(--font-weight-2);
  font-size: 1rem;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-3) var(--border-radius-3) 0 0;
}
.ap-panel-head-wrapper {
  display: flex;
  align-items: center;
}
.ap-panel-head-title {
  display: inline-flex;
  flex: 1;
  align-items: center;
  height: 1.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 1.25rem;
  font-weight: var(--font-weight-2);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
}
.ap-panel-head-title > .ap-typography,
.ap-panel-head-title > .ap-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ap-panel-head-title-text {
  line-height: 1.5rem;
}
.ap-panel-head.has-extra {
  padding: var(--spacing-6) var(--spacing-4) 0 var(--spacing-6);
}
.ap-panel-head.has-extra-extra {
  float: right;
  margin-left: auto;
  padding: 0;
  font-weight: normal;
  font-size: 1rem;
}
.ap-rtl .ap-panel-head.has-extra-extra {
  margin-right: auto;
  margin-left: 0;
}
.ap-panel-extra {
  position: absolute;
  top: var(--spacing-3);
  right: var(--spacing-4);
}
.ap-panel-body {
  padding: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
}
.ap-panel-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: var(--color-background-container);
  border-top: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}
.ap-panel-actions > li {
  float: left;
  margin: var(--spacing-3);
  text-align: center;
}
.ap-rtl .ap-panel-actions > li {
  float: right;
}
.ap-panel-actions > li > span {
  position: relative;
  display: block;
  min-width: 2rem;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
}
.ap-panel-actions > li > span:hover {
  transition: color 0.3s;
}
.ap-panel-actions > li > span > .Appkit4-icon {
  font-size: 1rem;
  line-height: 1.5rem;
}
.ap-panel-actions > li:not(:last-child) {
  border-right: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}
.ap-rtl .ap-panel-actions > li:not(:last-child) {
  border-right: none;
  border-left: 0.0625rem solid rgba(var(--color-background-tertiary-rgb), 0.24);
}

.ap-progress {
  position: relative;
  background-color: var(--color-text-disabled);
  border-radius: var(--border-radius-1);
}
.ap-progress ul {
  padding-inline-start: 0;
}
.ap-progress ul li {
  list-style: none;
}
.ap-progress .ap-progress-bar {
  position: relative;
  height: 100%;
  background: var(--color-background-primary);
  border-radius: var(--border-radius-1);
  transition: all 0.15s linear;
}
.ap-progress .ap-progress-bar::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--color-background-primary);
  transform: translate(50%, -50%);
}

.ap-progress-mark {
  position: absolute;
  top: 1.125rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ap-progress-mark:not(.is-vertical) li {
  width: 0;
}
.ap-progress-mark:not(.is-vertical) li:first-of-type .ap-progress-mark-text {
  transform: translateX(-8px);
}
.ap-progress-mark:not(.is-vertical) li:last-of-type .ap-progress-mark-text {
  transform: translateX(calc(-100% + 8px));
}
.ap-progress-mark:not(.is-vertical) li .ap-progress-mark-text {
  transform: translateX(-50%);
}

.ap-progress-mark-text {
  font-size: 1rem;
  letter-spacing: -0.025rem;
  font-weight: 400;
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--border-radius-2);
  color: var(--color-text-body);
  background-color: transparent;
  word-break: break-word;
  white-space: nowrap;
  display: table;
}
.ap-progress-mark-text span {
  display: inline-block;
  line-height: 1.5rem;
  height: 1.5rem;
}
.ap-progress-mark-text:hover {
  background: var(--color-background-container-alt);
  cursor: pointer;
}
.ap-progress-mark-text .active {
  color: var(--color-text-heading);
  font-weight: var(--font-weight-2);
}

.ap-progress-readonly .ap-progress-mark-text:hover {
  background: none;
  cursor: default;
}

.is-vertical {
  width: var(--spacing-2);
}
.is-vertical .ap-progress-bar::before {
  right: unset;
  bottom: unset;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
}
.is-vertical .ap-progress-mark {
  top: 0;
  width: 0;
  left: var(--spacing-4);
  height: 100%;
  flex-direction: column;
}
.is-vertical .ap-progress-mark li {
  height: 0;
}
.is-vertical .ap-progress-mark li:first-of-type .ap-progress-mark-text {
  transform: translateY(-8px);
}
.is-vertical .ap-progress-mark li:last-of-type .ap-progress-mark-text {
  transform: translateY(calc(-100% + 8px));
}
.is-vertical .ap-progress-mark li .ap-progress-mark-text {
  transform: translateY(-50%);
}

.is-horizontal {
  height: var(--spacing-2);
}

.ap-radio-container {
  display: flex;
}
.ap-radio-container ul,
.ap-radio-container li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ap-radio {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
  color: var(--color-text-body);
  line-height: 1.5rem;
}
.ap-radio .a-hidden-accessibility {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1.5rem;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1.5rem;
}
.ap-radio .a-hidden-accessibility .ap-radio-input {
  cursor: default;
  -webkit-appearance: radio;
  padding: initial;
  border: initial;
}
.ap-radio .a-hidden-accessibility input {
  transform: scale(0);
}
.ap-radio .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-radio .icon-radio-deselected-fill {
  color: var(--color-text-body);
}
.ap-radio .icon-radio-backdrop-outline {
  display: none;
  position: relative;
  color: var(--color-background-secondary);
}
.ap-radio .icon-radio-backdrop-outline .icon-radio-selected-fill {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-background-icon-primary);
  font-size: 1rem;
}
.ap-radio.ap-radio-checked .icon-radio-deselected-fill {
  display: none;
}
.ap-radio.ap-radio-checked .icon-radio-backdrop-outline {
  display: inline-flex;
}
.ap-radio.ap-radio-disabled {
  cursor: not-allowed;
}
.ap-radio.ap-radio-disabled .ap-radio-input {
  cursor: not-allowed;
}
.ap-radio.ap-radio-disabled .icon-radio-deselected-fill {
  opacity: var(--opacity-5);
}
.ap-radio.ap-radio-checked.ap-radio-disabled .icon-radio-backdrop-outline {
  opacity: var(--opacity-5);
}
.ap-radio.ap-radio-readonly .ap-radio-input {
  cursor: not-allowed;
}
.ap-radio.ap-radio-focused {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}

.ap-radio-label {
  padding-left: var(--spacing-2);
  font-size: 1rem;
  color: var(--color-text-body);
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
  cursor: pointer;
}
.ap-radio-label:empty {
  display: none;
}

.ap-radio-disabled + .ap-radio-label {
  cursor: not-allowed;
  opacity: var(--opacity-5);
}

.ap-radio-readonly + .ap-radio-label {
  cursor: not-allowed;
}

.ap-ratings {
  display: grid;
  justify-content: start;
  gap: var(--spacing-2);
  grid-auto-flow: column;
}
.ap-ratings .ap-ratings-item {
  background-color: var(--color-background-container);
  height: 2.5rem;
  width: 2.5rem;
  border-radius: var(--border-radius-2);
  padding: var(--spacing-3);
  box-shadow: 0 0 0 0.0625rem var(--color-background-border);
  display: flex;
  justify-content: center;
  align-items: center;
}
.ap-ratings .ap-ratings-item span.sentiment-icon,
.ap-ratings .ap-ratings-item .Appkit4-icon {
  color: var(--color-text-heading);
  height: 1.5rem;
  width: 1.5rem;
  font-size: 1rem;
}
.ap-ratings .ap-ratings-item span.sentiment-icon.is-macOS,
.ap-ratings .ap-ratings-item .Appkit4-icon.is-macOS {
  font-size: 2.5rem;
  transform: scale(0.5);
  height: auto;
  width: auto;
}
.ap-ratings .ap-ratings-item img.sentiment-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.ap-ratings .ap-ratings-item.selected {
  background-color: var(--color-background-primary);
  box-shadow: 0 0 0 0.0625rem rgba(var(--color-background-primary-rgb), 1);
}
.ap-ratings .ap-ratings-item.selected .Appkit4-icon {
  color: var(--color-background-secondary);
}
.ap-ratings .ap-ratings-item:hover {
  cursor: pointer;
}
.ap-ratings .ap-ratings-item:hover:not(.selected) {
  background-color: var(--color-background-hover);
}
.ap-ratings.readonly {
  gap: 0;
}
.ap-ratings.readonly .ap-ratings-item {
  background-color: unset;
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  box-shadow: none;
}
.ap-ratings.readonly .ap-ratings-item .Appkit4-icon {
  color: var(--color-background-hover-selected);
  height: 1.5rem;
  width: 1.5rem;
}
.ap-ratings.readonly .ap-ratings-item.selected .Appkit4-icon {
  color: var(--color-background-primary);
}
.ap-ratings.readonly .ap-ratings-item:hover {
  cursor: default;
  background-color: unset;
}
.ap-ratings .ap-ratings-slider-wrapper {
  width: 16.25rem;
  padding: var(--spacing-4);
  box-shadow: var(--level-1);
  border-radius: var(--border-radius-3);
  background-color: var(--color-background-container);
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-top {
  display: flex;
  justify-content: space-between;
  line-height: 1.5rem;
  height: 1.5rem;
  margin-bottom: 0.3125rem;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-top .ap-ratings-slider-heading {
  font-weight: var(--font-weight-2);
  color: var(--color-text-heading);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-top .ap-ratings-slider-label {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: var(--color-text-light);
  letter-spacing: -0.0125rem;
  align-items: center;
  display: flex;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-sentiment {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-sentiment span.sentiment-icon {
  font-size: 1.5rem;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-sentiment span.sentiment-icon:not(.is-macOS) {
  font-size: 1.1875rem;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider-sentiment img.sentiment-icon {
  height: 2rem;
  width: 2rem;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: var(--spacing-2);
  grid-template-columns: 2rem auto;
}
.ap-ratings .ap-ratings-slider-wrapper .ap-ratings-slider .ap-slider.ap-slider-horizontal.has-interval {
  height: auto;
}
.ap-ratings span.sentiment-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.ap-ratings span.sentiment-icon.icon-emoji-awful::before {
  content: "😠";
}
.ap-ratings span.sentiment-icon.icon-emoji-bad::before {
  content: "😔";
}
.ap-ratings span.sentiment-icon.icon-emoji-okay::before {
  content: "😐";
}
.ap-ratings span.sentiment-icon.icon-emoji-good::before {
  content: "🙂";
}
.ap-ratings span.sentiment-icon.icon-emoji-great::before {
  content: "😀";
}
.ap-ratings .Appkit4-icon,
.ap-ratings .sentiment-icon {
  letter-spacing: normal;
}
.ap-ratings .ap-ratings-sr-only {
  position: absolute;
  left: 0;
  top: 0;
  clip: rect(0 0 0 0);
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: no-wrap;
}

.ap-search-input {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  overflow: hidden;
  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s;
}
.ap-search-input:hover:not(.disabled) {
  background-color: var(--color-background-hover);
}
.ap-search-input:hover:not(.disabled) .ap-search-after-icon.showDeleteIcon:hover {
  background: var(--color-background-hover-selected);
}
.ap-search-input.disabled, .ap-search-input.readonly {
  cursor: not-allowed;
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-disabled);
  pointer-events: none;
}
.ap-search-input.disabled .ap-field-input, .ap-search-input.readonly .ap-field-input {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--color-text-heading);
}
.ap-search-input.disabled .ap-field-input::placeholder, .ap-search-input.readonly .ap-field-input::placeholder {
  opacity: var(--opacity-3);
}
.ap-search-input.disabled .icon-search-outline:before, .ap-search-input.readonly .icon-search-outline:before {
  opacity: var(--opacity-3);
}
.ap-search-input.disabled .icon-return-outline:before, .ap-search-input.readonly .icon-return-outline:before {
  opacity: var(--opacity-3);
}
.ap-search-input .icon-search-outline {
  position: absolute;
  left: 0;
  margin-left: var(--spacing-3);
  color: var(--color-text-light);
}
.ap-search-input .icon-search-outline::before {
  font-weight: var(--font-weight-2);
}
.ap-search-input .icon-search-outline.activeSearch {
  color: var(--color-text-heading);
}
.ap-search-input .ap-search-after-icon {
  position: absolute;
  opacity: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  letter-spacing: -0.025rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-1);
  visibility: hidden;
  color: var(--color-text-heading);
}
.ap-search-input .ap-search-after-icon.enter-icon {
  padding: var(--spacing-4);
  visibility: visible;
}
.ap-search-input .ap-search-after-icon.enter-icon .Appkit4-icon {
  font-size: 0.75rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: var(--color-text-heading);
}
.ap-search-input .ap-search-after-icon.enter-icon .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-search-input .ap-search-after-icon.enter-icon.showPressIcon {
  opacity: var(--opacity-7);
  display: inline-flex;
}
.ap-search-input .ap-search-after-icon.delete-icon {
  padding: var(--spacing-3);
  visibility: visible;
}
.ap-search-input .ap-search-after-icon.delete-icon.showDeleteIcon {
  cursor: not-allowed;
  opacity: var(--opacity-3);
  visibility: visible;
  display: inline-flex;
  color: var(--color-text-light);
}
.ap-search-input .ap-search-after-icon.delete-icon.showDeleteIcon:hover {
  cursor: not-allowed;
  background: var(--color-background-hover);
}
.ap-search-input .ap-search-after-icon.delete-icon.showDeleteIcon.active {
  cursor: pointer;
  opacity: var(--opacity-7);
  pointer-events: visibleFill;
}
.ap-search-input .ap-search-after-icon.delete-icon.showDeleteIcon.active .icon-circle-delete-outline {
  color: var(--color-text-heading);
}
.ap-search-input .ap-search-after-icon:hover:not(.disabled) {
  cursor: pointer;
  background-color: var(--color-background-hover-selected);
}
.ap-search-input:focus:not(.disabled):not(.readonly):not(.keyboard-focus), .ap-search-input:focus-within:not(.disabled):not(.readonly):not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-search-input:focus:not(.disabled):not(.readonly):not(.keyboard-focus) .icon-search-outline, .ap-search-input:focus-within:not(.disabled):not(.readonly):not(.keyboard-focus) .icon-search-outline {
  color: var(--color-text-heading);
}
.ap-search-input:focus-within:not(.disabled):not(.readonly).focus.keyboard-focus {
  outline: auto 0.125rem Highlight;
  /* Firefox */
  outline: auto 0.3125rem -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-search-input:focus-within:not(.disabled):not(.readonly).focus.keyboard-focus .icon-circle-delete-outline {
  outline: none;
  outline-offset: 0;
}
.ap-search-input:focus-within:not(.disabled):not(.readonly).focus.keyboard-focus .icon-search-outline {
  color: var(--color-text-heading);
}
.ap-search-input:focus-within:not(.disabled):not(.readonly).focus.keyboard-focus .icon-search-outline::before {
  color: var(--color-text-heading);
}
.ap-search-input:not(.disabled):not(.error):not(.readonly):not(:focus-within) .ap-search-wrapper:hover {
  background-color: var(--color-background-hover);
}
.ap-search-input .search-result {
  position: absolute;
  left: 99999px;
}

.ap-search-container {
  border-radius: var(--border-radius-3);
  transition: height 0.3s;
  transform-origin: bottom;
  position: relative;
  display: block;
  width: 100%;
  padding: var(--spacing-3);
  z-index: 1;
}
.ap-search-container .ap-search-list {
  padding-top: var(--spacing-3);
  z-index: 99;
  background-color: var(--color-background-container);
  color: var(--color-text-heading);
}
.ap-search-container .ap-search-list .ap-option-item:not(.noResult) .ap-option-label {
  padding-right: 2.75rem;
}
.ap-search-container .ap-search-list .ap-option-item:last-child {
  margin-bottom: 0;
}
.ap-search-container.show {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  background-color: var(--color-background-container);
}
.ap-search-container .ap-search-input input {
  height: 3rem;
  line-height: 3rem;
}
.ap-search-container .ap-search-input input::placeholder {
  color: var(--color-text-light);
  opacity: 1;
}
.ap-search-container .ap-field-input {
  width: 100%;
  color: var(--color-text-heading);
  -webkit-appearance: none;
  background-color: transparent;
  outline: 0 !important;
  cursor: pointer;
  letter-spacing: -0.025rem;
  padding: var(--spacing-4) 44px var(--spacing-4) 36px;
  transition: padding-top 0.3s, padding-bottom 0.3s, color 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-search-container .ap-field-input.disabled {
  pointer-events: none;
  cursor: not-allowed;
  border: solid 0.0625rem rgba(var(--color-background-tertiary), var(--opacity-2));
}
.ap-search-container .ap-field-input::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-light);
}
.ap-search-container .ap-field-input::-webkit-input-placeholder {
  color: red;
}

.ap-option-item.disabled .ap-option-label {
  opacity: var(--opacity-5);
}

.ap-slider-wrapper {
  width: 21rem;
  padding: var(--spacing-4) var(--spacing-3) 0;
  border-radius: var(--border-radius-3);
  background-color: var(--color-background-container);
}
.ap-slider-wrapper.with-input {
  padding: var(--spacing-3) var(--spacing-3) 0;
}
.ap-slider-wrapper.with-input .ap-slider-header {
  margin-bottom: 0;
}

.ap-slider {
  position: relative;
}
.ap-slider.disabled {
  opacity: var(--opacity-6);
  cursor: not-allowed;
}
.ap-slider.disabled .ap-slider-handle, .ap-slider.disabled .ap-slider-mark-text {
  cursor: not-allowed;
}

.ap-slider-horizontal {
  height: 1.5625rem;
  padding: 0.6875rem 0 0.75rem 0;
}
.ap-slider-horizontal.has-interval {
  height: 3.75rem;
}
.ap-slider-horizontal .ap-slider-handle {
  margin-top: -0.4375rem;
}

.ap-slider-rail {
  position: absolute;
  width: 100%;
  height: 0.125rem;
  background: var(--color-text-disabled);
  border-radius: var(--border-radius-1);
}

.ap-slider-track {
  position: absolute;
  height: 0.125rem;
  background: var(--color-background-primary);
  border-radius: var(--border-radius-1);
}

.ap-slider-handle-container {
  width: calc(100% - 16px);
  position: relative;
}
.ap-slider-handle-container.has-interval-tick {
  width: calc(100% - 8px);
}

.ap-slider-handle {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: var(--color-background-primary);
  border-radius: 50%;
  cursor: pointer;
}

.ap-slider-mark {
  position: absolute;
  top: 1.8125rem;
  left: 0;
  width: calc(100% - 16px);
}

.ap-slider-tick-container {
  position: absolute;
  left: 0;
  width: calc(100% - 8px);
}
.ap-slider-tick-container .ap-slider-mark-tick {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #E8E8E8;
}
.ap-slider-tick-container .ap-slider-mark-tick.active {
  background-color: var(--color-background-primary);
}

.ap-slider-mark-text {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  position: absolute;
  color: var(--color-text-light);
  opacity: var(--opacity-6);
}
.ap-slider-mark-text:hover {
  cursor: pointer;
}
.ap-slider-mark-text.active {
  opacity: var(--opacity-7);
  color: var(--color-text-body);
}
.ap-slider-mark-text.ap-slider-range-text {
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: #696969;
  pointer-events: none;
}

.ap-slider-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-2);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
}
.ap-slider-header .ap-slider-label {
  color: var(--color-text-heading);
  word-break: break-word;
}
.ap-slider-header .ap-slider-text {
  color: var(--color-text-light);
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.ap-slider-header .ap-slider-text .ap-field-demo-wrapper {
  width: 3.5rem;
}
.ap-slider-header .separator {
  padding: 0 var(--spacing-3);
}
.ap-slider-header .ap-field-wrapper {
  height: 32px !important;
}
.ap-slider-header input {
  width: 56px !important;
  height: 32px !important;
  text-align: center;
  margin: 0 !important;
}
.ap-slider-header input:not(:focus) {
  color: var(--color-text-light) !important;
}
.ap-slider-header .ap-slider-input-text {
  margin: 0 var(--spacing-3);
}

.gradient .ap-slider-track {
  background-image: linear-gradient(to right, #30d158 0%, #ff2d55 100%);
}

.gradient .ap-slider-handle-first {
  background: #30d158;
}

.gradient .ap-slider-handle-last {
  background: #ff2d55;
}

.ap-progress-stepper-wrapper {
  display: inline-flex;
  padding-top: var(--spacing-3);
  padding-left: 0;
}
.ap-progress-stepper-wrapper .ap-progress-stepper + .ap-progress-stepper {
  margin-left: var(--spacing-2);
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper {
  height: 80px;
}
.ap-progress-stepper-wrapper:not(.is-vertical-stepper) .ap-progress-stepper {
  width: 84px;
}
.ap-progress-stepper-wrapper .ap-progress-stepper {
  position: relative;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-bar {
  height: 0.25rem;
  border-radius: var(--border-radius-1);
  background-color: var(--color-text-disabled);
  position: absolute;
  left: 0;
  top: calc(-1 * var(--spacing-3));
  width: 100%;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-bar-inner {
  display: block;
  height: 100%;
  width: 0;
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-primary);
  transition: all 0.15s linear;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-bar-inner.bar-active {
  width: 100%;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-bar-inner.bar-warning {
  background-color: var(--color-background-danger);
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn {
  width: 100%;
  height: 2rem;
  border-radius: var(--border-radius-2);
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-3);
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn.normal-step-selected:hover {
  background-color: var(--color-background-hover);
  cursor: pointer;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn.warning-step-selected {
  background-color: rgba(var(--color-background-danger-rgb), var(--opacity-4));
  cursor: pointer;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn.warning-step-readonly {
  background-color: rgba(var(--color-background-danger-rgb), var(--opacity-4));
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn.upcoming-step-selected:hover {
  background-color: var(--color-background-hover);
  cursor: pointer;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .Appkit4-icon {
  font-size: 0.75rem;
  height: 1rem;
  width: 1rem;
  line-height: 1rem;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .normal-status {
  color: var(--color-background-primary);
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .warning-status {
  color: var(--color-text-error);
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .upcoming-status {
  opacity: var(--opacity-6);
  color: var(--color-text-body);
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn .upcoming-status-text {
  opacity: var(--opacity-6);
  color: var(--color-text-body);
  font-weight: normal;
}
.ap-progress-stepper-wrapper .ap-progress-stepper-btn-text {
  margin-left: var(--spacing-2);
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-background-tertiary);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-progress-stepper-wrapper.is-vertical-stepper {
  flex-direction: column;
  padding-left: var(--spacing-3) !important;
  padding-top: 0 !important;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper + .ap-progress-stepper {
  margin-top: var(--spacing-2);
  margin-left: 0;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper {
  display: flex;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper-bar {
  height: 100%;
  width: 0.25rem;
  left: calc(-1 * var(--spacing-3));
  top: 0;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper-bar-inner {
  height: 0;
  width: 100%;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper-bar-inner.bar-active {
  height: 100%;
}
.ap-progress-stepper-wrapper.is-vertical-stepper .ap-progress-stepper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  max-width: 10rem;
  margin-top: 0;
  padding: 0 var(--spacing-3);
}

.ap-switch {
  display: flex;
  align-items: center;
}
.ap-switch .ap-switch-btn {
  position: relative;
  margin: 0;
  cursor: pointer;
  display: flex;
  width: 2.25rem;
  height: 1.25rem;
  opacity: var(--opacity-7);
  border-radius: 0.75rem;
  background-color: #818181;
  padding: var(--spacing-1) 0;
  padding-left: var(--spacing-1);
  transition: padding 0.3s;
}
.ap-switch .ap-switch-btn .ap-switch-default {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  border-radius: 50%;
  background-color: var(--color-background-secondary);
}
@keyframes switchscale {
  0% {
    transform: scale(0);
    opacity: 0;
    transform-origin: center;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
    transform-origin: center;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    transform-origin: center;
  }
}
.ap-switch .ap-switch-btn.ap-switch-checked {
  background-color: var(--color-background-icon-primary);
  padding: var(--spacing-1) 0;
  padding-left: 1.125rem;
  opacity: var(--opacity-7);
}
.ap-switch .ap-switch-btn.ap-switch-checked .ap-check-mark-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ap-switch .ap-switch-btn.ap-switch-checked .ap-check-mark-container g {
  animation: switchscale 0.3s linear;
  fill: var(--color-background-icon-primary);
}
.ap-switch .ap-switch-btn.ap-switch-disabled {
  opacity: var(--opacity-6);
  cursor: not-allowed;
  background: #818181;
}
.ap-switch .ap-switch-btn.ap-switch-checked.ap-switch-disabled {
  opacity: var(--opacity-3);
  cursor: not-allowed;
  background-color: var(--color-background-icon-primary);
}
.ap-switch .ap-switch-btn.ap-switch-readonly {
  cursor: not-allowed;
  opacity: var(--opacity-6);
}
.ap-switch .ap-switch-btn.ap-switch-checked.ap-switch-readonly {
  cursor: not-allowed;
  opacity: var(--opacity-6);
}
.ap-switch .ap-switch-lg {
  width: 2.625rem;
  height: 1.5rem;
  padding: var(--spacing-1) var(--spacing-6) var(--spacing-1) var(--spacing-1);
}
.ap-switch .ap-switch-lg .ap-switch-default {
  width: 1.25rem;
  height: 1.25rem;
}
.ap-switch .ap-switch-lg.ap-switch-checked {
  padding: var(--spacing-1) var(--spacing-1) var(--spacing-1) var(--spacing-6);
}

.ap-switch-label {
  font-size: 1rem;
  padding-left: var(--spacing-3);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
  cursor: pointer;
  display: inline-block;
}
.ap-switch-label:empty {
  display: none;
}

.ap-switch-disabled + .ap-switch-label {
  opacity: var(--opacity-5);
  cursor: not-allowed;
}

.ap-switch-readonly + .ap-switch-label {
  cursor: not-allowed;
}

.ap-tabset-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  letter-spacing: -0.025rem;
}
.ap-tabset-container .ap-tabset-toggle-wrapper {
  width: fit-content;
  display: grid;
  border-radius: var(--border-radius-2);
  position: relative;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.stretched {
  width: 100%;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle {
  position: relative;
  display: grid;
  grid-row: 1;
  justify-items: center;
  align-items: baseline;
  height: 100%;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  line-height: 1.5rem;
  border-radius: var(--border-radius-2);
  transition: background-color 0.3s;
  outline-offset: var(--spacing-1);
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle > * {
  display: grid;
  column-gap: var(--spacing-2);
  grid-column-start: 1;
  grid-row-start: 1;
  align-items: center;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle .ap-tabset-toggle-active {
  visibility: hidden;
  font-weight: var(--font-weight-2);
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle .ap-tabset-toggle-inactive {
  visibility: visible;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.pinned {
  cursor: default;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.active .ap-tabset-toggle-active {
  visibility: visible;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.active .ap-tabset-toggle-inactive {
  visibility: hidden;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.has-label > *, .ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.has-icon > * {
  grid-template-columns: auto;
}
.ap-tabset-container .ap-tabset-toggle-wrapper .ap-tabset-toggle.disabled {
  pointer-events: none;
  opacity: var(--opacity-5);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline {
  height: calc(1.5rem + var(--spacing-3));
  column-gap: var(--spacing-7);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
  width: 100%;
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-selected);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle {
  height: calc(1.5rem + var(--spacing-3));
  padding-bottom: var(--spacing-3);
  color: var(--color-text-light);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle:not(.active):hover .ap-tabset-toggle-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
  width: 100%;
  border-radius: var(--border-radius-1);
  background-color: var(--color-text-light);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle:not(.active):hover .ap-tabset-toggle-active {
  visibility: visible;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle:not(.active):hover .ap-tabset-toggle-inactive {
  visibility: hidden;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle:not(.active):not(:hover) .ap-tabset-toggle-active {
  visibility: hidden;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle:not(.active):not(:hover) .ap-tabset-toggle-inactive {
  visibility: visible;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.125rem;
  width: 100%;
  border-radius: var(--border-radius-1);
  background-color: var(--color-background-primary);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.underline .ap-tabset-toggle.active .ap-tabset-toggle-active {
  color: var(--color-text-primary);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled {
  height: 2.5rem;
  padding: var(--spacing-2);
  column-gap: var(--spacing-2);
  background-color: var(--color-background-selected);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle {
  height: 2rem;
  border-radius: var(--border-radius-1);
  color: var(--color-text-body);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle:not(.active):hover {
  background-color: var(--color-background-hover-selected);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle .ap-tabset-toggle-active {
  color: var(--color-text-tertiary);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.active {
  background-color: var(--color-background-primary);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-label {
  padding: var(--spacing-2) var(--spacing-4);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-label > * {
  grid-template-columns: auto;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-icon {
  padding: var(--spacing-2) var(--spacing-3);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-icon span.Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-icon > * {
  grid-template-columns: auto;
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-label.has-icon {
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-3);
}
.ap-tabset-container .ap-tabset-toggle-wrapper.filled .ap-tabset-toggle.has-label.has-icon > * {
  grid-template-columns: auto auto;
}
.ap-tabset-container .ap-tabset-responsive-wrapper {
  display: flex;
  align-items: center;
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tab-move-ctrl {
  font-size: 0.75rem;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  letter-spacing: normal;
  cursor: pointer;
  transition: transform 0.3s;
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tab-move-ctrl.rotate {
  transform: rotate(180deg);
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tab-move-ctrl.disabled {
  opacity: var(--opacity-5);
  pointer-events: none;
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tab-move-ctrl.icon-left-chevron-outline {
  margin-right: var(--spacing-5);
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tab-move-ctrl.icon-right-chevron-outline {
  margin-left: var(--spacing-5);
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tabset-responsive-scroll-container {
  width: 100%;
  position: relative;
  overflow: auto;
  scrollbar-width: none;
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tabset-responsive-scroll-container::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.ap-tabset-container .ap-tabset-responsive-wrapper .ap-tabset-responsive-scroll-container .ap-tabset-toggle-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  transition: left 0.25s, right 0.25s;
}
.ap-tabset-container .ap-tabset-responsive-wrapper.underline .ap-tab-move-ctrl {
  margin-top: 0.3125rem;
  margin-bottom: 0.6875rem;
}
.ap-tabset-container .ap-tabset-responsive-wrapper.underline .ap-tabset-responsive-scroll-container {
  height: calc(1.5rem + var(--spacing-3));
}
.ap-tabset-container .ap-tabset-responsive-wrapper.underline .ap-tabset-responsive-scroll-container .ap-tabset-toggle-wrapper.pinned {
  padding-left: var(--spacing-7);
}
.ap-tabset-container .ap-tabset-responsive-wrapper.underline .ap-tabset-responsive-scroll-container .ap-tabset-toggle-wrapper .ap-tabset-toggle {
  outline-offset: -0.0625rem;
}
.ap-tabset-container .ap-tabset-responsive-wrapper.filled {
  background-color: var(--color-background-selected);
}
.ap-tabset-container .ap-tabset-responsive-wrapper.filled .ap-tabset-responsive-scroll-container {
  height: 2.5rem;
}
.ap-tabset-container .ap-tabset-responsive-wrapper.filled .ap-tabset-responsive-scroll-container .ap-tabset-toggle-wrapper.pinned {
  padding-left: 0;
}
.ap-tabset-container .overflow-button .icon-vertical-more-outline {
  width: 40px;
  height: 32px;
  line-height: 32px;
}
.ap-tabset-container .overflow-button .ap-button-dropdown {
  max-height: 17.5rem;
  overflow: auto;
}
.ap-tabset-container .overflow-button .custom:focus-visible {
  outline-offset: var(--spacing-1);
}

.ap-tag {
  position: relative;
  display: inline-block;
  padding: var(--spacing-2) var(--spacing-3) var(--spacing-2) var(--spacing-4);
  border-radius: 1rem;
  line-height: 1rem;
  letter-spacing: -0.025rem;
  color: var(--color-background-secondary);
  align-items: center;
  justify-content: space-between;
  user-select: none;
}
.ap-tag::after {
  border-radius: 1rem;
}
.ap-tag.ap-tag-disabled {
  opacity: 0.32;
  cursor: not-allowed;
}
.ap-tag.ap-tag-disabled .ap-tag-label {
  cursor: not-allowed;
}
.ap-tag.ap-tag-disabled .Appkit4-icon {
  cursor: not-allowed;
  color: var(--color-text-primary-rgb);
}
.ap-tag.ap-tag-disabled .Appkit4-icon:hover {
  cursor: not-allowed;
}
.ap-tag.ap-tag-disabled .icon-close-outline {
  cursor: not-allowed;
}
.ap-tag.ap-tag-disabled.ap-tag-default {
  background-color: rgba(var(--color-background-primary-rgb));
}
.ap-tag.ap-tag-sm {
  height: 1.5rem;
  font-size: 0.875rem;
  display: inline-flex;
  font-weight: var(--font-weight-2);
}
.ap-tag.ap-tag-sm .Appkit4-icon {
  width: 1rem;
  height: 1rem;
}
.ap-tag.ap-tag-sm .Appkit4-icon:before {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.025rem;
}
.ap-tag.ap-tag-sm .ap-tag-label {
  font-size: 0.875rem;
  line-height: 1rem;
}
.ap-tag.ap-tag-lg {
  height: 2rem;
  font-size: 1rem;
  display: inline-flex;
}
.ap-tag.ap-tag-lg .Appkit4-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.ap-tag.ap-tag-lg .Appkit4-icon:before {
  font-weight: var(--font-weight-2);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: normal;
}
.ap-tag.ap-tag-lg .ap-tag-label {
  font-size: 1rem;
  font-weight: var(--font-weight-2);
}
.ap-tag .ap-tag-label {
  margin-right: var(--spacing-2);
  letter-spacing: -0.025rem;
  white-space: nowrap;
}
.ap-tag .Appkit4-icon {
  color: var(--color-background-secondary);
  cursor: pointer;
  z-index: 1;
}
.ap-tag .Appkit4-icon:hover {
  cursor: pointer;
}
.ap-tag.ap-tag-default:not(.ap-tag-disabled) {
  background: var(--color-background-primary);
}
.ap-tag.ap-tag-default:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-default:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-default:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-default:not(.ap-tag-disabled):focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--color-background-primary), var(--opacity-1));
  outline: auto 2px Highlight; /* Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-tag.ap-tag-blue:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-blue:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-blue:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-blue:not(.ap-tag-disabled):focus {
  outline: none;
}
.ap-tag.ap-tag-green:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-green:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-green:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-green:not(.ap-tag-disabled):focus {
  outline: none;
}
.ap-tag.ap-tag-indigo:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-indigo:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-indigo:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-indigo:not(.ap-tag-disabled):focus {
  outline: none;
}
.ap-tag.ap-tag-orange:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-orange:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-orange:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-orange:not(.ap-tag-disabled):focus {
  outline: none;
}
.ap-tag.ap-tag-pink:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-pink:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-pink:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-pink:not(.ap-tag-disabled):focus {
  outline: none;
}
.ap-tag.ap-tag-purple:not(.ap-tag-disabled):hover::after {
  background-color: rgba(255, 255, 255, var(--opacity-3));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-purple:not(.ap-tag-disabled):active::after, .ap-tag.ap-tag-purple:not(.ap-tag-disabled):visited::after {
  background-color: rgba(0, 0, 0, var(--opacity-1));
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}
.ap-tag.ap-tag-purple:not(.ap-tag-disabled):focus {
  outline: none;
}

.ap-tooltip-react {
  position: fixed;
  visibility: visible;
  z-index: 10000;
  max-width: 15rem;
  color: var(--color-background-alt);
  font-size: 0.875rem;
  line-height: 1rem;
}
.ap-tooltip-react .ap-tooltip-reactContent {
  backdrop-filter: var(--blur-2);
  transition: transform 0.3s, visibility 0.3s, opacity 0.3s;
  padding: var(--spacing-3);
  background-color: rgba(var(--color-background-container-inverse-rgb), 0.72);
  border-radius: var(--border-radius-2);
  visibility: visible;
}
.ap-tooltip-react .ap-tooltip-reactContent:not(.show) {
  visibility: hidden;
  opacity: 0;
}
.ap-tooltip-react .ap-tooltip-text {
  color: var(--color-background-alt);
  font-size: 0.875rem;
  line-height: 1rem;
}
.ap-tooltip-react:not(.show) {
  visibility: hidden;
}
.ap-tooltip-react:not(.show).right {
  transform: translateX(-0.3125rem);
}
.ap-tooltip-react:not(.show).left {
  transform: translateX(0.3125rem);
}
.ap-tooltip-react:not(.show).top {
  transform: translateY(0.3125rem);
}
.ap-tooltip-react:not(.show).bottom {
  transform: translateY(-0.3125rem);
}

.ap-tree {
  margin: 0;
  padding: 0;
}
.ap-tree.no-outline .focus {
  outline: 0 !important;
}

.ap-files-fold {
  color: var(--color-text-body);
  padding: var(--spacing-4);
  margin-bottom: var(--spacing-2);
  letter-spacing: normal !important;
  user-select: none;
  list-style: none;
}
.ap-files-fold:last-child {
  margin-bottom: 0;
}
.ap-files-fold:focus {
  outline: 0;
}
.ap-files-fold .ap-fold-name.focus, .ap-files-fold .ap-tree-list-item.focus {
  outline: -webkit-focus-ring-color auto 0.3125rem;
  outline-offset: var(--spacing-1);
}
@supports (-moz-appearance: none) {
  .ap-files-fold .ap-fold-name.focus, .ap-files-fold .ap-tree-list-item.focus {
    box-shadow: 0 0 0 0.125rem #2474d6;
    border-radius: 0.125rem;
  }
}
.ap-files-fold .ap-fold-name {
  width: 100%;
  border-radius: var(--border-radius-2);
  font-size: 1rem;
  display: flex;
}
.ap-files-fold .ap-fold-name .Appkit4-icon {
  margin-right: var(--spacing-2);
}
.ap-files-fold .ap-fold-name .ap-file-title {
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ap-files-fold .ap-fold-name .ap-file-title:hover {
  font-weight: var(--font-weight-2);
}
.ap-files-fold .ap-tree-list {
  padding-left: var(--spacing-4);
}
.ap-files-fold .ap-tree-list.fadeInDown {
  display: block;
}
.ap-files-fold .ap-tree-list.fadeOutUp {
  display: none;
}
.ap-files-fold .ap-tree-list .ap-tree-list-item {
  display: flex;
}
.ap-files-fold .ap-tree-list .ap-tree-list-item:hover {
  cursor: pointer;
  color: var(--color-text-heading);
  font-weight: var(--font-weight-2);
}
.ap-files-fold .ap-tree-list .ap-tree-list-item .ap-file-title {
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ap-files-fold .ap-tree-list .ap-tree-list-item .Appkit4-icon {
  margin-right: var(--spacing-2);
}
.ap-files-fold.active, .ap-files-fold:hover {
  cursor: pointer;
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
}
.ap-files-fold.active .ap-fold-name {
  margin-bottom: 0;
}
.ap-files-fold.active .ap-fold-name, .ap-files-fold .ap-files-fold {
  background-color: var(--color-background-hover);
  margin-top: var(--spacing-4);
  padding: 0;
}
.ap-files-fold.active .ap-fold-name {
  margin-top: 0;
}
.ap-files-fold.normal .ap-tree-list {
  background-color: var(--color-background-alt);
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.ap-fileupload {
  display: inline-flex;
  width: 100%;
  user-select: none;
}
.ap-fileupload.ap-fileupload-modal {
  background-color: var(--color-background-container);
  box-shadow: var(--box-shadow-3);
  border-radius: var(--border-radius-3);
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-6) var(--spacing-6);
}
.ap-fileupload .ap-fileupload-wrapper {
  position: relative;
  width: 100%;
  letter-spacing: -0.025rem;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-title {
  align-items: center;
  color: var(--color-text-heading);
  display: flex;
  font-weight: var(--font-weight-2);
  justify-content: space-between;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  word-break: break-word;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-title.title-inline {
  font-size: 1.03125rem;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-title.title-modal {
  font-size: 1.25rem;
  margin-bottom: var(--spacing-4);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-title a {
  cursor: pointer;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-instruction {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
  word-break: break-word;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-instruction.instruction-inline {
  margin-bottom: 0.875rem;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-instruction.instruction-modal {
  margin-bottom: var(--spacing-4);
  margin-right: var(--spacing-3);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-instruction.instruction-modal-button {
  color: var(--color-text-body);
  margin-bottom: var(--spacing-6);
  margin-right: var(--spacing-3);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper {
  height: 7rem;
  position: relative;
  width: calc(100% - 8px);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper.content-inline {
  height: 4rem;
  width: 100%;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper.content-bottom {
  margin-bottom: var(--spacing-6);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content-shadow {
  background-color: transparent;
  border-radius: var(--border-radius-2);
  height: calc(100% + 16px);
  left: calc(-1 * var(--spacing-3));
  padding: var(--spacing-3);
  position: absolute;
  top: calc(-1 * var(--spacing-3));
  width: calc(100% + 16px);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content-shadow.file-over {
  background-color: #F3F3F3;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content {
  background-color: transparent;
  border: dashed 0.125rem var(--color-text-body);
  border-radius: var(--border-radius-2);
  display: flex;
  height: 100%;
  padding: var(--spacing-3);
  position: absolute;
  width: 100%;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content .ap-fileupload-drop-btn {
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  cursor: pointer;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content .ap-fileupload-drop-btn .ap-fileupload-drop-span {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: var(--color-text-body);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content .ap-fileupload-drop-btn .ap-fileupload-drop-browse-span {
  color: var(--color-text-link-primary);
  font-weight: var(--font-weight-2);
  text-decoration: underline;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-content-wrapper .ap-fileupload-content .ap-fileupload-drop-btn .ap-fileupload-drop-browse-span:hover {
  text-decoration: none;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists {
  margin-bottom: var(--spacing-6);
  transition: all 0.5s ease-in-out;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList:not(:last-child) {
  margin-bottom: var(--spacing-2);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded {
  align-items: center;
  background-color: transparent;
  border-radius: var(--border-radius-2);
  display: flex;
  height: 3rem;
  justify-content: space-between;
  padding-left: var(--spacing-3);
  position: relative;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded.modal-loaded {
  margin-left: calc(-1 * var(--spacing-3));
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded:hover {
  background-color: var(--color-background-container-alt);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded:hover .icon-file-upload-fill,
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded:hover .ap-fileupload-fileName,
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded:hover .ap-fileupload-fileSize,
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded:hover .icon-close-outline {
  color: #252525;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .doc-text {
  fill: var(--color-text-heading);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .fail-color {
  fill: #C52A1A !important;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .upload-fail {
  color: #C52A1A !important;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-loaded-close {
  margin-right: var(--spacing-2);
  background-color: transparent;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-2);
  font-size: 1rem;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-loaded-close:hover {
  background-color: var(--color-background-hover);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-loaded-close .icon-close-outline {
  color: var(--color-text-heading);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-fileSpan {
  width: 23.125rem;
  display: flex;
  align-items: center;
  margin-left: var(--spacing-2);
  margin-right: auto;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-fileSpan .ap-fileupload-fileName {
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-heading);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-fileSpan .ap-fileupload-fileSize {
  font-size: 1rem;
  margin-left: var(--spacing-3);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-body);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .icon-file-upload-fill {
  color: var(--color-text-heading);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-percentage {
  margin-right: var(--spacing-3);
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: #696969;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-progressbar {
  width: calc(100% - 8px);
  height: 0.25rem;
  margin: var(--spacing-2) 0 0;
  border-radius: var(--border-radius-1);
  background-color: #D2D7E2;
  position: absolute;
  bottom: var(--spacing-2);
  left: var(--spacing-2);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-progressbar.upload-fail-progress {
  background-color: #f3d4d1 !important;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-progressbar-inside {
  height: 0.25rem;
  border-radius: var(--border-radius-1);
  background-color: #415385;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-progressbar-inside.upload-fail-progress-inside {
  background-color: #C52A1A !important;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-success {
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-2);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-loaded .ap-fileupload-success .Appkit4-icon {
  color: #21812D;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-error-message {
  margin-top: var(--spacing-3);
  margin-bottom: var(--spacing-4);
  font-size: 0.75rem;
  line-height: 0.875rem;
  letter-spacing: -0.025rem;
  text-align: center;
  color: #C52A1A;
  word-break: break-word;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-fileLists .ap-fileupload-fileList .ap-fileupload-error-message span {
  cursor: pointer;
  text-decoration: underline;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer {
  display: flex;
  justify-content: space-between;
  overflow: auto;
  padding: var(--spacing-2);
  margin: calc(-1 * var(--spacing-2));
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer-delete-attachment {
  align-items: flex-end;
  display: flex;
  flex-shrink: 0;
  margin-right: var(--spacing-4);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer-delete-attachment span {
  display: inline-block;
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  color: var(--color-text-primary);
  cursor: pointer;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-footer .ap-fileupload-footer-btn {
  margin-right: var(--spacing-3);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-action {
  position: absolute;
  top: var(--spacing-4);
  right: var(--spacing-4);
  display: flex;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-action .ap-fileupload-title-close .Appkit4-icon {
  color: var(--color-text-heading);
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-action .ap-fileupload-title-close {
  background-color: transparent;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-2);
  font-size: 1rem;
}
.ap-fileupload .ap-fileupload-wrapper .ap-fileupload-action .ap-fileupload-title-close:hover {
  background-color: var(--color-background-hover);
}
.ap-fileupload input[type=file],
.ap-fileupload input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
  opacity: 0;
  visibility: visible;
  width: 0%;
  position: absolute;
  height: 0%;
  left: 0;
}
.ap-fileupload input[type=file] {
  opacity: 0;
}

.ap-navigation-wrapper {
  height: 100vh;
  background-color: transparent;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}
.ap-navigation-wrapper:not(.solidnav) {
  background-color: var(--color-background-container-alt);
}
.ap-navigation-wrapper .ap-navigation-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-6);
  width: 100%;
}
.ap-navigation-wrapper .ap-navigation-heading .ap-navigation-title-wrapper {
  display: flex;
  align-items: center;
}
.ap-navigation-wrapper .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-logo {
  margin-right: var(--spacing-2);
  width: 2.625rem;
  height: 2.5rem;
  mask-image: url("../images/PwC_logo_simplified.svg");
  -webkit-mask-image: url("../images/PwC_logo_simplified.svg");
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  background-color: var(--color-text-heading);
  display: block;
}
.ap-navigation-wrapper .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-title {
  color: var(--color-text-heading);
  line-height: 1.5rem;
  display: block;
  letter-spacing: -0.025rem;
}
.ap-navigation-wrapper .ap-navigation-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  padding: 1.75rem var(--spacing-4) 4.5rem;
  width: 100%;
  height: calc(100% - 4.5rem);
  overflow-y: scroll;
  scrollbar-width: none;
}
.ap-navigation-wrapper .ap-navigation-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.ap-navigation-wrapper::-webkit-scrollbar {
  display: none;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child:focus-visible .ap-navigation-item:hover {
  backdrop-filter: none;
}
.ap-navigation-wrapper .ap-navigation-item {
  display: flex;
  align-items: center;
  padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-3);
  height: 3rem;
  line-height: 3rem;
  border-radius: var(--border-radius-2);
  background-color: transparent;
  color: var(--color-text-body);
  position: relative;
}
.ap-navigation-wrapper .ap-navigation-item:focus-visible {
  outline-offset: -0.0625rem;
}
.ap-navigation-wrapper .ap-navigation-item:hover {
  cursor: pointer;
  backdrop-filter: blur(20px);
  background-color: var(--color-background-hover-selected);
}
.ap-navigation-wrapper .ap-navigation-item .itemContent {
  margin-left: var(--spacing-2);
  letter-spacing: -0.025rem;
}
.ap-navigation-wrapper .ap-navigation-item .suffixIcon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  transform: rotate(0);
  transition: transform 0.3s;
}
.ap-navigation-wrapper .ap-navigation-item .suffixIcon.rotate {
  transform: rotate(-180deg);
}
.ap-navigation-wrapper .ap-navigation-item.selected {
  font-weight: var(--font-weight-2);
  background-color: var(--color-background-hover-selected);
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child > .nav-item-child {
  list-style: none;
  height: 0;
  min-height: 0px;
  overflow: hidden !important;
  padding: 0px 1.5rem;
  opacity: 0;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child {
  padding: 0;
  position: relative;
  height: auto;
  min-height: 3.125rem;
  opacity: 1;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .nav-item .ap-navigation-item .prefixIcon {
  padding-left: 1.5rem;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .nav-item .ap-navigation-item .prefixIcon.has-icon {
  padding-left: 0;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .prefix-content {
  margin-left: var(--spacing-7);
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .prefix-content + .itemContent {
  padding-left: 0;
}
.ap-navigation-wrapper .ap-nav-item.nav-with-child.nav-item-expanded > .nav-item-child .nav-item:not(:last-child) {
  margin-bottom: 0.25rem;
}
.ap-navigation-wrapper .ap-navigation-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4.5rem;
  display: block;
  padding: var(--spacing-4);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(var(--color-background-container-alt-rgb), 0.48);
}
.ap-navigation-wrapper .ap-navigation-footer .ap-navigation-item:hover {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(var(--color-background-hover-selected-rgb), 0.48);
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-heading {
  justify-content: center;
  padding: var(--spacing-4) var(--spacing-5);
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-logo {
  display: none;
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-heading .ap-navigation-title-wrapper .ap-navigation-title {
  display: none;
}
.ap-navigation-wrapper.collapse-nav .ap-nav-item.nav-with-child {
  width: 3rem;
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-item {
  width: 3rem;
  padding: var(--spacing-4);
  border-radius: var(--border-radius-2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-item:hover {
  backdrop-filter: blur(20px);
  background-color: rgba(var(--color-background-hover-selected-rgb), 0.48);
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-item .prefix-content {
  height: 3rem;
  width: 3rem;
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-item .itemContent {
  display: none;
}
.ap-navigation-wrapper.collapse-nav .ap-navigation-item .suffixIcon {
  display: none;
}
.ap-navigation-wrapper.collapse-nav .ap-nav-item.nav-with-child:focus-visible .ap-navigation-item {
  backdrop-filter: none;
}
.ap-navigation-wrapper.solid-nav {
  box-shadow: 0 0 0.0625rem 0 var(--color-background-default);
  background-color: var(--color-background-alt);
}
.ap-navigation-wrapper.solid-nav .ap-navigation-item:hover {
  background-color: var(--color-background-selected);
}
.ap-navigation-wrapper.solid-nav .ap-navigation-item.selected {
  font-weight: var(--font-weight-2);
  background-color: var(--color-background-selected);
}
.ap-navigation-wrapper.solid-nav .ap-navigation-footer {
  background-color: rgba(var(--color-background-alt-rgb), 0.48);
}
.ap-navigation-wrapper.solid-nav .ap-navigation-footer .ap-navigation-item:hover {
  background-color: rgba(var(--color-background-selected-rgb), 0.48);
}
.ap-navigation-wrapper .ap-divider {
  width: 100%;
  height: 0.0625rem;
  margin: 0.75rem 0;
  background-color: var(--color-background-hover-selected);
}

.ap-navigation-demo-wrapper {
  background-color: var(--color-background-default);
}

.ap-navigation-wrapper .ap-navigation-item.selected,
.ap-navigation-wrapper .ap-navigation-item.active {
  font-weight: var(--font-weight-2);
  background-color: var(--color-background-hover-selected);
}
.ap-navigation-wrapper.solid-nav .ap-navigation-item.selected,
.ap-navigation-wrapper.solid-nav .ap-navigation-item.active {
  background-color: var(--color-background-selected);
}

.ap-nav-item .nav-item:focus-visible {
  outline-offset: -0.0625rem;
}

.ap-nav-item.nav-with-child:focus-visible {
  outline-offset: 0.0625rem;
}

.a-date-picker-header.no-outline .ap-field-icon-btn {
  outline: none;
}

.a-react-date-picker-calendar,
.a-date-range-picker-calendar {
  width: 320px;
}

.a-react-date-picker-calendar,
.a-date-range-picker-calendar,
.a-date-range-picker-double-calendar {
  padding: var(--spacing-3);
  border-radius: var(--spacing-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  position: absolute;
  z-index: 10002;
  right: 0;
  cursor: default;
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
  pointer-events: auto;
  transform-origin: top right;
}
.a-react-date-picker-calendar.a-has-no-outline .ap-tile,
.a-date-range-picker-calendar.a-has-no-outline .ap-tile,
.a-date-range-picker-double-calendar.a-has-no-outline .ap-tile {
  outline: none !important;
}
.a-react-date-picker-calendar.closed,
.a-date-range-picker-calendar.closed,
.a-date-range-picker-double-calendar.closed {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}
.a-react-date-picker-calendar.react-date-picker--closed,
.a-date-range-picker-calendar.react-date-picker--closed,
.a-date-range-picker-double-calendar.react-date-picker--closed {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}

.a-double-calendar-container {
  display: flex;
  overflow: hidden;
  width: 40.5rem;
  outline: none !important;
}
.a-double-calendar-container .ap-datepicker-right {
  margin-left: calc(var(--spacing-8) - var(--spacing-3));
}
.a-double-calendar-container.no-bottom-left-border-radius {
  border-bottom-left-radius: 0px;
}
.a-double-calendar-container.no-bottom-right-border-radius {
  border-bottom-right-radius: 0px;
}
.a-double-calendar-container.open {
  z-index: 100;
}
.a-double-calendar-container .a-double-calendar-divider {
  width: 0.625rem;
  background-color: var(--color-background-container);
}

.react-date-picker .ap-field.text-readonly .ap-field-wrapper {
  cursor: default;
}

.react-date-picker,
.react-daterange-picker {
  position: relative;
}

.a-date-range-picker-double-calendar {
  width: 41.5rem;
  z-index: 100;
  position: absolute;
}
.a-date-range-picker-double-calendar .a-date-range-picker-double-calendar-inner {
  display: inline-flex;
}
.a-date-range-picker-double-calendar .a-double-calendar-container {
  margin-top: 0px;
  margin-bottom: 0px;
}
.a-date-range-picker-double-calendar.closed {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}

.a-double-calendar-has-left-panel {
  width: 51.125rem;
}

.a-date-inline-focusable:focus {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .a-date-inline-focusable:focus {
    /* IE10+ CSS styles go here */
    outline: 0.0625rem solid #4d90fe;
  }
}
@supports (-ms-ime-align: auto) {
  .a-date-inline-focusable:focus {
    outline: 0.0625rem solid #4d90fe;
  }
}
.a-date-inline-focusable.a-calendar-no-outline {
  outline: none;
}

.keyboard-inner-highlight {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keyboard-inner-highlight {
    /* IE10+ CSS styles go here */
    outline: 0.0625rem solid #4d90fe;
  }
}
@supports (-ms-ime-align: auto) {
  .keyboard-inner-highlight {
    outline: 0.0625rem solid #4d90fe;
  }
}

.keyboard-date-view-inner-highlight .ap-tile-init-focus {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keyboard-date-view-inner-highlight .ap-tile-init-focus {
    /* IE10+ CSS styles go here */
    outline: 0.0625rem solid #4d90fe;
  }
}
@supports (-ms-ime-align: auto) {
  .keyboard-date-view-inner-highlight .ap-tile-init-focus {
    outline: 0.0625rem solid #4d90fe;
  }
}

.no-need-outline-highlight {
  outline: none !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ap-nav-next-button.keyboard-inner-highlight,
  .ap-nav-prev-button.keyboard-inner-highlight,
  .ap-calendar__month-view.keyboard-inner-highlight,
  .ap-calendar__year-view.keyboard-inner-highlight,
  .ap-calendar__decade-view.keyboard-inner-highlight {
    outline: auto 2px Highlight;
    /* Firefox */
    outline: auto 5px -webkit-focus-ring-color;
    /* Webkit, Safari */
    outline-offset: var(--spacing-1);
  }
}

.a-date-range-picker-calendar-has-left-panel {
  display: flex;
  width: 29.625rem;
  box-shadow: var(--level-3);
  border-radius: var(--spacing-3);
  overflow: hidden;
  padding: var(--spacing-3);
}
.a-date-range-picker-calendar-has-left-panel .ap-calendar {
  box-shadow: none;
  border-radius: 0px;
  padding: 0;
  width: 304px;
}

.a-clendar-left-menu {
  width: 9.625rem;
}
.a-clendar-left-menu .a-calenar-menu-header {
  font-size: 1.25rem;
  color: var(--color-text-heading);
  height: 2.5rem;
  font-weight: var(--font-weight-2);
  display: flex;
  align-items: center;
  padding: var(--spacing-3);
  margin-bottom: var(--spacing-2);
}
.a-clendar-left-menu .a-clendar-left-menu-content {
  display: grid;
  grid-gap: var(--spacing-2);
}
.a-clendar-left-menu .a-clendar-left-menu-content .a-clendar-left-menu-content-item {
  color: var(--color-text-body);
  font-size: 1rem;
  height: 40px;
  cursor: pointer;
  padding: var(--spacing-3);
  display: flex;
  align-items: center;
}
.a-clendar-left-menu .a-clendar-left-menu-content .a-clendar-left-menu-content-item.checked {
  font-weight: var(--font-weight-2);
}
.a-clendar-left-menu .a-clendar-left-menu-content .a-clendar-left-menu-content-item.disabled {
  color: rgba(var(--color-text-light-rgb), var(--opacity-6));
  cursor: not-allowed;
}

.ap-calendar .ap-tile {
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  padding: var(--spacing-3);
  color: var(--color-text-body);
  height: 2.5rem;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-2);
  cursor: pointer;
}
.ap-calendar .ap-tile:not(.ap-single-day--excluded):not(.disabled):not(.ap-tile-rangeStart):not(.ap-tile-rangeEnd):not(.ap-tile-rangeBothEnds):not(.ap-tile-within):not(.ap-single-day--neighboringMonth):not(.ap-tile-hoverEnd):not(.ap-tile-hoverStart):hover {
  background-color: var(--color-background-hover);
}
.ap-calendar .ap-tile.ap-tile-nav:focus-visible {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ap-calendar .ap-tile.ap-tile-nav:focus-visible {
    /* IE10+ CSS styles go here */
    outline: 0.0625rem solid #4d90fe;
  }
}
@supports (-ms-ime-align: auto) {
  .ap-calendar .ap-tile.ap-tile-nav:focus-visible {
    outline: 0.0625rem solid #4d90fe;
  }
}
.ap-calendar .ap-single-day--neighboringMonth {
  background-color: var(--color-background-container) !important;
  cursor: not-allowed !important;
  color: rgba(var(--color-text-light-rgb), var(--opacity-6)) !important;
  outline: none;
  font-weight: 400 !important;
}
.ap-calendar .ap-tile-now:not(.ap-single-day--neighboringMonth) {
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-tile-rangeBothEnds:not(.ap-single-day--neighboringMonth) {
  background-color: var(--color-background-primary) !important;
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__decade-view__years,
.ap-calendar .ap-calendar__year-view__months {
  display: grid;
  grid-gap: var(--spacing-2);
  gap: var(--spacing-2);
  grid-template-columns: 98px 100px 98px;
}
.ap-calendar .ap-calendar__decade-view__years .ap-single-month,
.ap-calendar .ap-calendar__decade-view__years .ap-single-year,
.ap-calendar .ap-calendar__year-view__months .ap-single-month,
.ap-calendar .ap-calendar__year-view__months .ap-single-year {
  width: 6.125rem;
  height: 3.875rem;
  line-height: 2.5rem;
  letter-spacing: -0.025rem;
}
.ap-calendar .ap-calendar__decade-view__years .ap-single-month:nth-of-type(3n-1),
.ap-calendar .ap-calendar__decade-view__years .ap-single-year:nth-of-type(3n-1),
.ap-calendar .ap-calendar__year-view__months .ap-single-month:nth-of-type(3n-1),
.ap-calendar .ap-calendar__year-view__months .ap-single-year:nth-of-type(3n-1) {
  width: 6.25rem;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-within,
.ap-calendar .ap-calendar__decade-view__years .ap-tile-range-bothEnds,
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hover-middle,
.ap-calendar .ap-calendar__year-view__months .ap-tile-within,
.ap-calendar .ap-calendar__year-view__months .ap-tile-range-bothEnds,
.ap-calendar .ap-calendar__year-view__months .ap-tile-hover-middle {
  background-color: var(--color-background-primary);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeStart,
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeEnd,
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeStart,
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeEnd {
  background-color: var(--color-background-primary);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeStart,
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverStart,
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeStart,
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverStart {
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeStart:nth-of-type(3n-2),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverStart:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeStart:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverStart:nth-of-type(3n-2) {
  margin-left: 0;
  margin-right: calc(-1 * var(--spacing-1));
  padding-right: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.25rem;
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeStart:nth-of-type(3n),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverStart:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeStart:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverStart:nth-of-type(3n) {
  margin-left: 0;
  margin-right: 0;
  width: 6.125rem;
  border-radius: var(--border-radius-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeStart:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverStart:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeStart:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverStart:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)) {
  margin-left: 0;
  margin-right: calc(-1 * var(--spacing-1));
  padding-right: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.375rem;
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-within:nth-of-type(3n-2),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-within:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):nth-of-type(3n-2) {
  margin-left: 0;
  margin-right: calc(-1 * var(--spacing-1));
  padding-right: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.25rem;
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-within:nth-of-type(3n),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-within:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):nth-of-type(3n) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: 0;
  padding-left: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.25rem;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-within:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-within:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd):not(:nth-of-type(3n-2)):not(:nth-of-type(3n)) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: calc(-1 * var(--spacing-1));
  width: 6.5rem;
  border-radius: 0;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeBothEnds,
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeBothEnds {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: var(--spacing-3) !important;
  padding-right: var(--spacing-3) !important;
  border-radius: var(--border-radius-2) !important;
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeBothEnds:nth-of-type(3n-2), .ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeBothEnds:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeBothEnds:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeBothEnds:nth-of-type(3n) {
  width: 6.125rem;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeBothEnds:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeBothEnds:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)) {
  width: 6.25rem;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeEnd,
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverEnd,
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeEnd,
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverEnd {
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeEnd:nth-of-type(3n-2),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverEnd:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeEnd:nth-of-type(3n-2),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverEnd:nth-of-type(3n-2) {
  margin-left: 0;
  margin-right: 0;
  width: 6.125rem;
  border-radius: var(--border-radius-2);
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeEnd:nth-of-type(3n),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverEnd:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeEnd:nth-of-type(3n),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverEnd:nth-of-type(3n) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: 0;
  padding-left: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.25rem;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
}
.ap-calendar .ap-calendar__decade-view__years .ap-tile-rangeEnd:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__decade-view__years .ap-tile-hoverEnd:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-rangeEnd:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)),
.ap-calendar .ap-calendar__year-view__months .ap-tile-hoverEnd:not(:nth-of-type(3n-2)):not(:nth-of-type(3n)) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: 0;
  padding-left: calc(var(--spacing-3) + var(--spacing-1));
  width: 6.375rem;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
}
.ap-calendar .ap-calendar__month-view .ap-weekdays {
  display: flex;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing-2);
  column-gap: var(--spacing-2);
  margin-bottom: var(--spacing-2);
}
.ap-calendar .ap-calendar__month-view .ap-days {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile {
  width: 2.5rem;
  margin-bottom: var(--spacing-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-selected-tile:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds) {
  color: var(--color-background-secondary);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-selected-tile:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds) {
  background-color: var(--color-background-primary-light);
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-selected-tile.ap-single-day--excluded {
  color: rgba(var(--color-text-tertiary-rgb), var(--opacity-3));
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-single-day--neighboringMonth {
  background-color: var(--color-background-container);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-rangeStart:nth-of-type(7n),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hoverStart:not(.ap-tile-hoverBothEnds):nth-of-type(7n) {
  margin-left: 0;
  margin-right: 0;
  padding-left: var(--spacing-3);
  width: 2.5rem;
  border-radius: var(--border-radius-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-rangeStart:not(:nth-of-type(7n)),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hoverStart:not(.ap-tile-hoverBothEnds):not(:nth-of-type(7n)) {
  margin-left: 0;
  margin-right: calc(-1 * var(--spacing-1));
  padding-right: calc(var(--spacing-3) + var(--spacing-1));
  width: 2.625rem;
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-active:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds):nth-of-type(7n-6),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-hoverBothEnds):nth-of-type(7n-6) {
  margin-left: 0;
  margin-right: calc(-1 * var(--spacing-1));
  padding-right: calc(var(--spacing-3) + var(--spacing-1));
  width: 2.625rem;
  border-radius: var(--border-radius-2) 0 0 var(--border-radius-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-active:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds):nth-of-type(7n),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-hoverBothEnds):nth-of-type(7n) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: 0;
  padding-left: calc(var(--spacing-3) + var(--spacing-1));
  width: 2.625rem;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-active:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds):not(:nth-of-type(7n-6)):not(:nth-of-type(7n)),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hover-middle:not(.ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-hoverBothEnds):not(:nth-of-type(7n-6)):not(:nth-of-type(7n)) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: calc(-1 * var(--spacing-1));
  width: 2.75rem;
  border-radius: 0;
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-rangeEnd:nth-of-type(7n-6),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hoverEnd:not(.ap-tile-hoverBothEnds):nth-of-type(7n-6) {
  margin-left: 0;
  margin-right: 0;
  padding-right: var(--spacing-3);
  width: 2.5rem;
  border-radius: var(--border-radius-2);
}
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-rangeEnd:not(:nth-of-type(7n-6)),
.ap-calendar .ap-calendar__month-view .ap-days .ap-tile-hoverEnd:not(.ap-tile-hoverBothEnds):not(:nth-of-type(7n-6)) {
  margin-left: calc(-1 * var(--spacing-1));
  margin-right: 0;
  padding-left: calc(var(--spacing-3) + var(--spacing-1));
  width: 2.625rem;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) 0;
}
.ap-calendar.disable-bottom-radius {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ap-calendar button {
  margin: 0;
  border: 0;
}
.ap-calendar button:enabled:hover {
  cursor: pointer;
}
.ap-calendar .ap-single-weekday {
  font-weight: var(--font-weight-2);
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  color: var(--color-text-body);
  width: 40px;
}
.ap-calendar .ap-nav-bar {
  height: 2.5rem;
  margin-bottom: var(--spacing-2);
  display: flex;
  align-items: center;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper {
  flex: 1 1 auto !important;
  display: flex;
  justify-content: left;
  align-items: center;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper.second {
  display: flex;
  justify-content: flex-start;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button {
  padding: var(--spacing-3);
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group {
  font-size: 1.25rem;
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
  color: var(--color-text-heading);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button.disabled,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group.disabled {
  cursor: default;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month {
  margin-right: 4px;
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-year,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-year,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month {
  padding: var(--spacing-3);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  font-weight: var(--font-weight-2);
  font-size: 1.25rem;
  line-height: 1.5rem;
  border-radius: var(--border-radius-2);
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-year:hover,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month:hover,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-year:hover,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month:hover {
  background-color: var(--color-background-hover);
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-year:focus-visible,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month:focus-visible,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-year:focus-visible,
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month:focus-visible {
  z-index: 999;
  outline: 0.0625rem dotted #444;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-year:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-year:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month:focus-visible {
    /* IE10+ CSS styles go here */
    outline: 0.0625rem solid #4d90fe;
  }
}
@supports (-ms-ime-align: auto) {
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-year:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button .ap-center-button-month:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-year:focus-visible,
  .ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-button-group .ap-center-button-month:focus-visible {
    outline: 0.0625rem solid #4d90fe;
  }
}
.ap-calendar .ap-nav-bar .a-center-button-wrapper .a-center-text {
  cursor: default;
}
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper {
  display: inline-flex;
}
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-prev-button {
  margin-right: var(--spacing-2);
}
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-next-button,
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-prev-button {
  border-radius: var(--border-radius-2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--color-text-body);
  padding: var(--spacing-3);
  box-sizing: content-box;
}
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-next-button.disabled,
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-prev-button.disabled {
  cursor: not-allowed;
}
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-next-button:hover,
.ap-calendar .ap-nav-bar .ap-nav-button-wrapper .ap-nav-prev-button:hover {
  background-color: var(--color-background-hover);
}
.ap-calendar .ap-nav-bar .a-nav-small-wrapper {
  display: flex;
}
.ap-calendar abbr {
  text-decoration: none;
  cursor: inherit;
}
.ap-calendar .ap-tile {
  letter-spacing: -0.025rem;
  /* &:hover {
    //color: $color-text-body;
  } */
}
.ap-calendar .ap-tile.ap-tile-key-nav:not(.ap-single-day--excluded) {
  z-index: 100;
  outline: 0.3125rem auto -webkit-focus-ring-color;
}
.ap-calendar .ap-tile.ap-tile-active, .ap-calendar .ap-tile.ap-tile-hover-middle:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds, .ap-single-day--neighboringMonth) {
  background-color: var(--color-background-primary-light);
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-tile.ap-tile-active:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds, .ap-single-day--neighboringMonth) {
  background-color: var(--color-background-primary-light);
  color: var(--color-text-tertiary);
}
.ap-calendar .ap-tile.ap-tile-active:not(.ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-hoverStart, .ap-tile-hoverEnd, .ap-tile-rangeBothEnds, .ap-single-day--neighboringMonth).ap-single-day--excluded:not(.ap-single-day--neighboringMonth) {
  color: rgba(var(--color-text-tertiary-rgb), var(--opacity-3));
}
.ap-calendar .ap-tile.ap-tile-hoverBothEnds .ap-calendar .ap-tile.ap-tile-rangeBothEnds, .ap-calendar .ap-tile.ap-tile-hoverStart, .ap-calendar .ap-tile.ap-tile-hoverEnd, .ap-calendar .ap-tile.ap-tile-rangeStart, .ap-calendar .ap-tile.ap-tile-rangeEnd:not(.ap-single-day--neighboringMonth) {
  background-color: var(--color-background-primary);
  color: var(--color-text-tertiary);
  font-weight: var(--font-weight-2);
}
.ap-calendar .ap-tile.ap-single-day--excluded:not(.ap-single-day--neighboringMonth) {
  cursor: not-allowed !important;
  color: rgba(var(--color-text-tertiary-rgb), var(--opacity-3));
}
.ap-calendar .ap-tile.ap-single-day--excluded:not(.ap-single-day--neighboringMonth, .ap-tile-rangeStart, .ap-tile-rangeEnd, .ap-tile-rangeBothEnds) {
  color: rgba(var(--color-text-light-rgb), var(--opacity-3));
}
.ap-calendar .ap-tile.ap-tile-within.ap-single-day--excluded:not(.ap-single-day--neighboringMonth) {
  color: rgba(var(--color-text-tertiary-rgb), var(--opacity-3));
}

.ap-table-demo th {
  background-color: var(--color-background-container);
}
.ap-table-demo td:last-child {
  text-align: right;
}
.ap-table-demo table.table-head-padding td:first-child {
  padding-left: 8px;
}

.animated {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: var(--opacity-7);
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: var(--opacity-7);
  }
}
.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    opacity: var(--opacity-7);
  }
  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    opacity: var(--opacity-7);
  }
}
.ap-table {
  width: 100%;
  padding: var(--spacing-2) var(--spacing-3);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  border-radius: var(--border-radius-3);
  background-color: var(--color-background-container);
  letter-spacing: -0.025rem;
}
.ap-table.ap-table-checkbox td:first-child {
  padding-left: var(--spacing-3);
}
.ap-table table {
  border-spacing: 0 var(--spacing-2);
  width: 100%;
  font-size: 1rem;
  border-collapse: separate;
}
.ap-table table thead th {
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: var(--font-weight-2);
  color: var(--color-text-heading);
  transition: background-color 0.3s;
  padding: 0;
}
.ap-table table thead th:not(:last-child) .header-container {
  margin-right: var(--spacing-2);
}
.ap-table table thead th .header-container {
  display: flex;
  justify-content: space-between;
  column-gap: var(--spacing-3);
  height: 3rem;
  line-height: 1.5rem;
  padding: 0 var(--spacing-4);
  margin-bottom: var(--spacing-2);
  border-radius: var(--border-radius-2);
  align-items: center;
}
.ap-table table thead th .header-container .Appkit4-icon.sort-table {
  color: var(--color-text-heading);
  font-size: 0.75rem;
  opacity: 1;
  line-height: 1.5rem;
}
.ap-table table thead th .header-container .Appkit4-icon.sort-table::before {
  font-weight: var(--font-weight-2);
}
.ap-table table thead th .header-container .Appkit4-icon.sort-table.hidden {
  display: none;
}
.ap-table table thead th .header-container .Appkit4-icon.sort-table.sorted {
  color: var(--color-background-icon-primary);
}
.ap-table table thead th .header-container .Appkit4-icon.sort-table.sorted.descendant {
  transform: rotate(-180deg);
}
.ap-table table thead th .header-container:hover .Appkit4-icon.sort-table:not(.sorted) {
  color: var(--color-background-icon-primary);
}
.ap-table table thead th .header-container.has-sorter:hover {
  cursor: pointer;
  background-color: var(--color-background-hover);
}
.ap-table table thead th .header-container.has-sorter .ap-table-header-description-sr-only {
  display: none;
}
.ap-table table thead th .header-container .ap-checkbox-container {
  margin-right: 0 !important;
}
.ap-table table thead th .header-container .ap-checkbox-container.check-all {
  margin-right: 0 !important;
}
.ap-table table thead th .header-container .ap-checkbox-container.check-all:not(.show) {
  display: flex !important;
}
.ap-table table thead th .header-container .sort-icon-wrapper {
  width: 2.125rem;
  height: 2.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ap-table table thead th .header-container.head-sorted {
  background-color: var(--color-background-hover);
}
.ap-table table thead th .header-container.start-slot {
  flex-direction: row-reverse;
}
.ap-table table thead th .header-container.start-slot > * {
  margin-left: auto;
}
.ap-table table thead th .header-container.start-slot .Appkit4-icon.sort-table {
  padding-right: 0;
  transition: opacity 0.3s, padding-right 0.3s, color 0.3s;
  color: var(--color-background-icon-primary);
}
.ap-table table thead th .header-container.start-slot .sort-icon-wrapper {
  margin-left: -0.3125rem;
}
.ap-table table thead th .header-container.start-slot:not(:hover) .sort-icon-wrapper:not(:focus-within) .Appkit4-icon.sort-table:not(.sorted) {
  padding-right: var(--spacing-3);
  opacity: 0;
}
.ap-table table thead th .header-container.end-slot {
  flex-direction: row;
}
.ap-table table thead th .header-container.end-slot > * {
  margin-right: auto;
}
.ap-table table thead th .header-container.end-slot .Appkit4-icon.sort-table {
  padding-left: 0;
  transition: opacity 0.3s, padding-left 0.3s, color 0.3s;
  color: var(--color-background-icon-primary);
}
.ap-table table thead th .header-container.end-slot .sort-icon-wrapper {
  margin-right: -0.3125rem;
}
.ap-table table thead th .header-container.end-slot:not(:hover) .sort-icon-wrapper:not(:focus-within) .Appkit4-icon.sort-table:not(.sorted) {
  padding-left: var(--spacing-3);
  opacity: 0;
}
.ap-table table tbody tr {
  padding: 0;
  height: 3rem;
  color: var(--color-text-body);
  transition: background-color 0.3s, transform 0.3s;
}
.ap-table table tbody tr.selected {
  background-color: var(--color-background-hover);
}
.ap-table table tbody tr:hover {
  background-color: var(--color-background-hover);
}
.ap-table table tbody tr td {
  line-height: 1.5rem;
  padding: var(--spacing-4);
}
.ap-table table tbody tr td:first-child {
  border-top-left-radius: var(--border-radius-2);
  border-bottom-left-radius: var(--border-radius-2);
}
.ap-table table tbody tr td:last-child {
  border-top-right-radius: var(--border-radius-2);
  border-bottom-right-radius: var(--border-radius-2);
}
.ap-table table tbody tr td span {
  vertical-align: inherit;
}
.ap-table table tbody tr td .ap-checkbox-wrapper {
  vertical-align: inherit;
  margin: 0 var(--spacing-3) 0 0;
  position: relative;
}
.ap-table table tbody tr td .ap-checkbox-wrapper .Appkit4-icon::before {
  font-weight: var(--font-weight-2);
}
.ap-table table tbody tr td .ap-checkbox-wrapper .icon-check-blank-outline {
  color: var(--color-text-body);
}
.ap-table table tbody tr td .ap-checkbox-wrapper .icon-check-backdrop-outline {
  display: none;
  position: relative;
  color: var(--color-text-primary);
  font-size: 0.75rem;
}
.ap-table table tbody tr td .ap-checkbox-wrapper .a-checkbox-text {
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
  font-weight: normal;
  color: var(--color-text-body);
}
.ap-table table tbody tr td .ap-checkbox-wrapper input[type=checkbox] {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.ap-table table tbody tr td .ap-checkbox-wrapper input[type=checkbox]:checked ~ .icon-check-blank-outline {
  display: none;
}
.ap-table table tbody tr td .ap-checkbox-wrapper input[type=checkbox]:checked ~ .icon-check-backdrop-outline {
  display: inline-block;
}
.ap-table.ap-table-condensed tbody tr {
  height: 2rem !important;
}
.ap-table.ap-table-condensed tbody tr td {
  padding-top: var(--spacing-2) !important;
  padding-bottom: var(--spacing-2) !important;
}
.ap-table.ap-table-striped tbody tr.striped {
  background-color: var(--color-background-selected);
}
.ap-table.ap-table-striped tbody tr.striped:hover, .ap-table.ap-table-striped tbody tr.striped.selected {
  background-color: var(--color-background-hover-selected);
}

.ap-timepicker {
  display: flex;
  gap: var(--spacing-2);
}
.ap-timepicker.ap-12-hour :not(:last-child) {
  flex: 1 0 5.0625rem;
}

.ap-timepicker-selector {
  position: relative;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  overflow: hidden;
  cursor: text;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper .ap-timepicker-input-label {
  position: absolute;
  max-width: calc(100% - 2.5rem);
  color: var(--color-text-light);
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  transition: all 0.3s;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper .ap-timepicker-input {
  width: 100%;
  color: var(--color-text-heading);
  background-color: transparent;
  outline: 0;
  transition: margin 0.3s, padding-bottom 0.3s, color 0.3s;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper .Appkit4-icon.icon-down-chevron-outline {
  position: absolute;
  right: var(--spacing-2);
  top: var(--spacing-2);
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  color: var(--color-text-body);
  border-radius: var(--border-radius-1);
  transition: all 0.3s;
  cursor: pointer;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper .Appkit4-icon.icon-down-chevron-outline::before {
  font-weight: var(--font-weight-1);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper .Appkit4-icon.icon-down-chevron-outline.rotate {
  transform: rotate(-180deg);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.hide-selector-title {
  transition: none;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.hide-selector-title .ap-timepicker-input {
  margin: var(--spacing-4) var(--spacing-3);
  padding: 0;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.hide-title .ap-timepicker-input-label {
  opacity: 0;
  transition: none;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.hide-title .ap-timepicker-input {
  padding: var(--spacing-4) 2rem var(--spacing-4) var(--spacing-3);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.error {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.error .ap-timepicker-input-label {
  color: var(--color-text-error);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.error .ap-timepicker-input {
  color: var(--color-text-error);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.error .ap-timepicker-input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text-error);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.error .Appkit4-icon.icon-down-chevron-outline {
  color: var(--color-text-error);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:hover:not(.error) {
  background-color: var(--color-background-hover);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:focus-within:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:focus-within.keyboard-focus {
  outline: auto 2px Highlight; /* Firefox */
  outline: auto 5px -webkit-focus-ring-color; /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:focus-within:not(.hide-title) .ap-timepicker-input-label {
  left: var(--spacing-3);
  top: var(--spacing-3);
  line-height: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
  padding-bottom: 1px;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:focus-within:not(.hide-title) .ap-timepicker-input {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 0 0.5625rem;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:not(:focus-within):not(.hide-title) .ap-timepicker-input-label {
  font: var(--typography-3) "PwC Helvetica Neue";
  left: var(--spacing-3);
  top: 0.875rem;
  line-height: 1.25rem;
  padding-bottom: 1px;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper:not(:focus-within):not(.hide-title) .ap-timepicker-input {
  margin: 1.875rem var(--spacing-3) 0;
  padding: 0 2rem 0.3125rem 0;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.has-length:not(.hide-title) .ap-timepicker-input-label {
  left: var(--spacing-3);
  top: var(--spacing-3);
  line-height: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
}
.ap-timepicker-selector .ap-timepicker-input-wrapper.has-length:not(.hide-title) .ap-timepicker-input {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 0 0.5625rem;
}
.ap-timepicker-selector.meridiem {
  width: 5.0625rem;
}

.ap-timepicker-dropdown-list {
  position: absolute;
  display: block;
  width: 100%;
  top: 3.5rem;
  left: 0;
  padding: var(--spacing-3);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  color: var(--color-text-body);
  max-height: 17.5rem;
  visibility: visible;
  opacity: 1;
  transition: visibility 0.3s, transform 0.3s;
  pointer-events: auto;
  overflow: auto;
  z-index: 9999;
}
.ap-timepicker-dropdown-list:not(.show) {
  transform: translateY(var(--spacing-4));
  visibility: hidden;
  opacity: 0;
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item {
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  font: var(--typography-3) "PwC Helvetica Neue";
  padding: var(--spacing-4) var(--spacing-3);
  border-radius: var(--border-radius-2);
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  color: var(--color-text-body);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item:not(:last-child) {
  margin-bottom: var(--spacing-2);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item:hover {
  background-color: var(--color-background-hover);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item .ap-timepicker-dropdown-item-char.bold {
  font-weight: var(--font-weight-2);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item.disabled {
  cursor: not-allowed;
  opacity: var(--opacity-5);
  color: var(--color-text-body);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item.active {
  background-color: var(--color-background-selected);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item.move:not(.disabled) {
  background-color: var(--color-background-hover);
  box-shadow: 0 0 0 0.0625rem var(--color-background-tertiary);
}
.ap-timepicker-dropdown-list .ap-timepicker-dropdown-item.move:not(.disabled):hover {
  background-color: var(--color-background-hover-selected);
}

.ap-timepicker-input-wrapper-outer.error .ap-field-content {
  color: var(--color-text-error);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;
  background-color: var(--color-background-container);
  border-radius: var(--border-radius-2);
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  overflow: hidden;
  cursor: text;
  transition: background-color 0.3s, box-shadow 0.3s;
  flex-wrap: wrap;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.hide-selector-title {
  transition: none;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.hide-selector-title .ap-timepicker-input {
  margin: var(--spacing-4) var(--spacing-3);
  padding: 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.hide-title .ap-timepicker-input-label {
  opacity: 0;
  transition: none;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.hide-title .ap-timepicker-input {
  padding: var(--spacing-4) 2rem var(--spacing-4) var(--spacing-3);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .ap-timepicker-input-label {
  position: absolute;
  max-width: calc(100% - 2.5rem);
  color: var(--color-text-light);
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  transition: all 0.3s;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .ap-timepicker-input-container {
  display: inline-flex;
  width: 100%;
  transition: margin 0.3s, padding-bottom 0.3s, color 0.3s;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .ap-timepicker-input-container .ap-timepicker-input {
  width: 1.125rem;
  padding: 0;
  color: var(--color-text-heading);
  background-color: transparent;
  outline: 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .ap-timepicker-input-container .ap-timepicker-input.meridiem {
  width: 1.5rem;
  margin-left: var(--spacing-1);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .ap-timepicker-input-container .ap-timepicker-colon {
  width: 0.3125rem;
  align-self: center;
  text-align: center;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper .Appkit4-icon.icon-time-outline {
  position: absolute;
  right: var(--spacing-2);
  top: var(--spacing-2);
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  color: var(--color-text-body);
  border-radius: var(--border-radius-1);
  transition: all 0.3s;
  cursor: pointer;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.error {
  background-color: rgba(var(--color-text-error-rgb), var(--opacity-3));
  box-shadow: inset 0 0 0 0.0625rem var(--color-text-error);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.error .ap-timepicker-input-label {
  color: var(--color-text-error);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.error .ap-timepicker-input {
  color: var(--color-text-error) !important;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.error .ap-timepicker-input:-webkit-autofill {
  -webkit-text-fill-color: var(--color-text-error);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.error .Appkit4-icon.icon-time-outline {
  color: var(--color-text-error);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:hover:not(.error):not(.disabled) {
  background-color: var(--color-background-hover);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border) !important;
  cursor: not-allowed;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.readonly), .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.readonly) {
  opacity: var(--opacity-5);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled input, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly input {
  cursor: not-allowed;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled .Appkit4-icon, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly .Appkit4-icon {
  cursor: not-allowed;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled.noValue .ap-timepicker-input-label, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly.noValue .ap-timepicker-input-label {
  font: var(--typography-3) "PwC Helvetica Neue" !important;
  left: var(--spacing-3) !important;
  top: var(--spacing-4) !important;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled.noValue .ap-timepicker-input-container, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly.noValue .ap-timepicker-input-container {
  display: none;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.hide-title) .ap-timepicker-input-label, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.hide-title) .ap-timepicker-input-label {
  left: var(--spacing-3);
  top: var(--spacing-3);
  line-height: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
  padding-bottom: 1px;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.hide-title) .ap-timepicker-input-container, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.hide-title) .ap-timepicker-input-container {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 2rem 0.5625rem 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon {
  color: var(--color-text-light);
  visibility: visible;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.disabled:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper.readonly:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input {
  color: var(--color-text-heading);
  visibility: visible;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.keyboard-focus), .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.keyboard-focus) {
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary), 0 0 0 0.25rem rgba(var(--color-background-primary-rgb), var(--opacity-3));
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within.keyboard-focus, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within.keyboard-focus {
  outline: auto 2px Highlight;
  /* Firefox */
  outline: auto 5px -webkit-focus-ring-color;
  /* Webkit, Safari */
  outline-offset: var(--spacing-1);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.hide-title) .ap-timepicker-input-label, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.hide-title) .ap-timepicker-input-label {
  left: var(--spacing-3);
  top: var(--spacing-3);
  line-height: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
  padding-bottom: 1px;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.hide-title) .ap-timepicker-input-container, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.hide-title) .ap-timepicker-input-container {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 2rem 0.5625rem 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon {
  color: var(--color-text-light);
  visibility: visible;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):focus-within:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input {
  color: var(--color-text-heading);
  visibility: visible;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):not(:focus-within):not(.hide-title) .ap-timepicker-input-label, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):not(:focus-within):not(.hide-title) .ap-timepicker-input-label {
  font: var(--typography-3) "PwC Helvetica Neue";
  left: var(--spacing-3);
  top: var(--spacing-4);
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):not(:focus-within):not(.hide-title) .ap-timepicker-input-container, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):not(:focus-within):not(.hide-title) .ap-timepicker-input-container {
  margin: 1.875rem var(--spacing-3) 0;
  padding: 0 2rem 0.3125rem 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required):not(:focus-within):not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon {
  color: transparent;
  visibility: hidden;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled).has-length:not(.hide-title) .ap-timepicker-input-label, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required).has-length:not(.hide-title) .ap-timepicker-input-label {
  left: var(--spacing-3);
  top: var(--spacing-3);
  line-height: 0.75rem;
  font-size: 0.75rem;
  letter-spacing: -0.2px;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled).has-length:not(.hide-title) .ap-timepicker-input-container, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required).has-length:not(.hide-title) .ap-timepicker-input-container {
  margin: var(--spacing-7) var(--spacing-3) 0;
  padding: 0 2rem 0.5625rem 0;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input::placeholder {
  visibility: visible;
}
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.disabled).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon, .ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-input,
.ap-timepicker-input-wrapper-outer .ap-timepicker-input-wrapper:not(.required).has-length:not(.hide-title) .ap-timepicker-input-container .ap-timepicker-colon {
  color: var(--color-text-heading);
  visibility: visible;
}

.ap-composite-timepicker-dropdown-container {
  padding: var(--spacing-3);
  border-radius: var(--spacing-3);
  box-shadow: var(--box-shadow-3);
  background-color: var(--color-background-container);
  position: absolute;
  z-index: 10002;
  right: 0;
  cursor: default;
  transition: visibility 0.3s, opacity 0.3s, transform 0.3s;
  pointer-events: auto;
  transform-origin: top right;
}
.ap-composite-timepicker-dropdown-container.a-has-no-outline .ap-tile {
  outline: none !important;
}
.ap-composite-timepicker-dropdown-container.closed {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}
.ap-composite-timepicker-dropdown-container.react-date-picker--closed {
  visibility: hidden;
  opacity: 0;
  transform: scale(0.1);
}
.ap-composite-timepicker-dropdown-container .composite-time-picker-dropdown-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 8px;
}
.ap-composite-timepicker-dropdown-container .composite-time-picker-dropdown-container.hour-24 {
  grid-template-columns: 1fr 1fr;
}
.ap-composite-timepicker-dropdown-container .composite-time-picker-dropdown-container .ap-meridiem-field .ap-field-input {
  margin-top: 12px;
}
.ap-composite-timepicker-dropdown-container .composite-time-picker-dropdown-container .ap-field-title {
  width: 60px;
}

.ap-trigger-popup {
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 9999;
  /* &-zoom-enter&-zoom-enter-active,
  &-zoom-appear&-zoom-appear-active {
    animation-name: rcTriggerZoomIn;
    animation-play-state: running;
  }

  &-zoom-leave&-zoom-leave-active {
    animation-name: rcTriggerZoomOut;
    animation-play-state: running;
  } */
}
.ap-trigger-popup-hidden {
  display: none;
}
.ap-trigger-popup-zoom-enter, .ap-trigger-popup-zoom-appear {
  opacity: 0;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.ap-trigger-popup-zoom-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
}
.ap-trigger-popup-arrow {
  width: 0px;
  height: 0px;
  background: #000;
  border-radius: 100vw;
  box-shadow: 0 0 0 3px black;
  z-index: 1;
}
@keyframes rcTriggerZoomIn {
  0% {
    transform: scale(0, 0);
    transform-origin: 50% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
}
@keyframes rcTriggerZoomOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 50%;
    opacity: 1;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: 50% 50%;
    opacity: 0;
  }
}
/*# sourceMappingURL=index-build.css.map */
